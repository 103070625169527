import React, { useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfimationAction from "../ConfirmationAction/ConfirmationAction";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
});

function createData(row) {
    return {
        ...row,
        history: [
            { date: "2020-01-05", customerId: "11091700", amount: 3 },
            { date: "2020-01-02", customerId: "Anonymous", amount: 1 },
        ],
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    console.log(row);
    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>

                <TableCell component="th" scope="row">
                    {row.id}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>

                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">
                    {row.is_admin == 0 ? (
                        <ConfimationAction
                            message="Make Admin"
                            icon={<SupervisorAccountIcon size="small" />}
                            parentAction={() => props.removeUser(row)}
                        />
                    ) : (
                        <ConfimationAction
                            message="Remove Admin"
                            icon={<SupervisorAccountIcon size="small" />}
                            parentAction={() => props.removeUser(row)}
                        />
                    )}
                </TableCell>
                <TableCell>
                    <ConfimationAction
                        message=""
                        icon={<DeleteIcon size="small" />}
                        parentAction={() => props.removeUser(row)}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                Users Created
                            </Typography>
                            {/* <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Subject</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.causer_logs && row.causer_logs.map((log) => (
                    <TableRow key={log.id + "_logging"}>
                      <TableCell component="th" scope="row">
                        {log.log_name}
                      </TableCell>
                      <TableCell>{log.description}</TableCell>
              
                      <TableCell>{moment(log.created_at).startOf('day').fromNow()}</TableCell>
                      <TableCell>{log.subject_id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> */}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
    createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
    createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
    createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];

export default function ActiveUserTable(props) {
    let { headers } = props;
    let buildHeader = (header) => {
        console.log(header);
        return (
            <TableCell align="left" key={`myheader-${header}`}>
                {header}
            </TableCell>
        );
    };
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {props.headers.map((header) => {
                            return buildHeader(header);
                        })}
                        <TableCell>Remove User</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row) => (
                        <Row
                            key={row.id + "_active_row"}
                            row={createData(row)}
                            removeUser={props.removeUser}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
