import React, { useState, useContext, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import SnackbarAlert from "../../Features/SnackBar/SnackbarAlert";
import { LoginContext } from "../../../context/LoginContext";
import { TextField, InputLabel, FormHelperText } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { set } from "lodash";

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 20,
    },
}));

const LoginForm = (props) => {
    let classes = useStyles();
    let history = useHistory();
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [snack, setsnack] = useState(false);
    const [remember, setremember] = useState(false);
    const [loggedInUser, setloggedInUser, getUserLoggedIn] = useContext(
        LoginContext
    );
    const LOCALSTORAGENAME = "NFS_VPN_USERNAME";
    useEffect(() => {
        if (localStorage.getItem(LOCALSTORAGENAME)) {
            setremember(true);
            setemail(localStorage.getItem(LOCALSTORAGENAME));
        }
    }, []);
    const handleChangeEmail = (e) => {
        setemail(e.currentTarget.value);
    };
    const handleChangePassword = (e) => {
        setpassword(e.currentTarget.value);
    };
    const handleRemember = (e) => {
        if (!e.target.checked && localStorage.getItem(LOCALSTORAGENAME)) {
            localStorage.removeItem(LOCALSTORAGENAME);
        }

        setremember(e.target.checked);
    };
    const submitLogin = (e) => {
        e.preventDefault();
        axios.get("/sanctum/csrf-cookie").then((response) => {
            axios
                .post("/login", {
                    email,
                    password,
                })
                .then((response) => {
                    props.setsuccess(true);
                    props.seterror(false);
                    setpassword("");
                    getUserLoggedIn(() => {
                        setTimeout(() => {
                            history.push("/home");
                        }, 1500);
                    });
                    if (remember) {
                        localStorage.setItem(LOCALSTORAGENAME, email);
                    }
                    localStorage.setItem("NFS_VPN_LOGIN", true);
                })
                .catch((error) => {
                    props.setsuccess(false);
                    props.seterror(true);
                    console.log("error");
                    props.seterrorMessage("Ohh No");
                    console.log(error);
                });
        });
    };
    return (
        <Card className={classes.root} raised>
            <form onSubmit={submitLogin} noValidate className={classes.form}>
                <Typography
                    color="secondary"
                    variant="h6"
                    component="h1"
                    style={{ margin: "15px" }}
                    align="center"
                >
                    Login
                </Typography>
                {props.error && (
                    <Typography
                        color="error"
                        variant="body1"
                        component="p"
                        style={{ margin: "15px" }}
                        align="center"
                    >
                        Username or Password is Incorrect
                    </Typography>
                )}

                <div>
                    <TextField
                        required
                        label="Email Required"
                        fullWidth={true}
                        variant="filled"
                        onChange={handleChangeEmail}
                        value={email}
                        error={props.error}
                    />
                    <TextField
                        required
                        label="Password Required"
                        fullWidth={true}
                        type="password"
                        variant="filled"
                        onChange={handleChangePassword}
                        value={password}
                        error={props.error}
                    />
                </div>
                <Button
                    color="secondary"
                    variant="contained"
                    size="medium"
                    type="submit"
                >
                    Continue
                </Button>
            </form>
            <CardActions>
                <div>
                    <Typography variant="caption">Remember email</Typography>
                    <br />
                    <Switch
                        checked={remember}
                        onChange={handleRemember}
                        name="remember"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </div>
            </CardActions>
        </Card>
    );
};

export default LoginForm;
