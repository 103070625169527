import React, { useState, useEffect } from "react";
import axios from "axios";
import TransitionsModal from "../../Features/Modal/TransitionsModal";
import CreateClient from "../../Forms/CreateClient/CreateClient";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link, useHistory } from "react-router-dom";
import { Grid, Typography, Paper, Grow, TextField } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import sync from "../../../images/insync.svg";
import ClientCard from "../../Features/Cards/Client/ClientCard";
import { Slide } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import DeviceCard from "../../Features/Cards/Device/DeviceCard";
import LoadingPage from "../LoadingPage/LoadingPage";

const useStyles = makeStyles((theme) => ({
    title: {
        margin: "2rem",
    },

    searchBar: {
        margin: theme.spacing(3),

        width: "100%",
    },
    pagination: {
        margin: 15,
    },
    devicesContainer: {},
}));

const DevicesPage = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [devices, setdevices] = useState();
    const [page, setpage] = useState(1);
    const [searchResults, setsearchResults] = useState();
    const [paginationDetails, setpaginationDetails] = useState({});
    const [loading, setloading] = useState(false);
    const getDevices = (page = 1) => {
        setloading(true);
        axios
            .get("api/protected/devices")
            .then((results) => {
                setloading(false);
                setpaginationDetails(results.data);
                setdevices(results.data.data);
                setsearchResults(results.data.data);
            })
            .catch((error) => {
                setloading(false);
                if (error.response.status === 401) {
                    console.log("auth error");
                    history.push("/");
                }
            });
    };

    const handleChange = (event, value) => {
        getPage(value);
    };

    const getPage = (page) => {
        setloading(true);
        console.log(`${paginationDetails.links[page].url}`);
        axios
            .get(`api/protected/devices`, {
                params: {
                    page,
                },
            })
            .then((results) => {
                setloading(false);
                setpaginationDetails(results.data);
                setdevices(results.data.data);
                setsearchResults(results.data.data);
            })
            .catch((error) => {
                setloading(false);
                console.log(error);
            });
    };

    const handleSearch = (e, item) => {
        !e.target.value && getDevices();
        let search = e.target.value;
        if (search.includes(".")) {
            search = search.split(".").join("");
        }

        if (e.target.value.length > 3) {
            axios
                .get("api/protected/search/devices", {
                    params: {
                        search,
                    },
                })
                .then((results) => {
                    setloading(false);

                    setpaginationDetails(results.data.search);
                    setdevices(results.data.search.data);
                    setsearchResults(results.data.search.data);
                })
                .catch((error) => {
                    setloading(false);
                });
        }
    };

    useEffect(() => {
        getDevices();
    }, []);

    let deviceCards = searchResults
        ? searchResults.map((device, i) => {
              return (
                  <Grid
                      item
                      align="flex-start"
                      xs={6}
                      sm={4}
                      md={3}
                      lg={2}
                      key={`${device.name}-${device.id}`}
                  >
                      <DeviceCard device={device} client={device.client} />
                  </Grid>
              );
          })
        : "No Results";

    return (
        <div className={classes.root}>
            {loading ? (
                <LoadingPage></LoadingPage>
            ) : (
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="flex-start"
                    className={classes.root}
                >
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-basic"
                            label="Search"
                            placeholder="Search by names, ip address, type, os"
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            onChange={handleSearch}
                            className={classes.searchBar}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                        className={classes.devicesContainer}
                    >
                        <Grid item xs={12}>
                            {/* <Typography variant="body1" align="left">
                                Total Results: {paginationDetails.total}
                            </Typography> */}
                            <Typography variant="body1" align="left">
                                {paginationDetails.from} -{" "}
                                {paginationDetails.to} of{" "}
                                {paginationDetails.total}
                            </Typography>
                        </Grid>
                        {deviceCards}
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid item>
                            <Pagination
                                count={
                                    paginationDetails.last_page
                                        ? paginationDetails.last_page
                                        : 0
                                }
                                page={
                                    paginationDetails.current_page
                                        ? paginationDetails.current_page
                                        : 0
                                }
                                onChange={handleChange}
                                className={classes.pagination}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default DevicesPage;
