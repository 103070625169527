import { makeStyles } from "@material-ui/core/styles";
import TabletAndroidIcon from "@material-ui/icons/TabletAndroid";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LaptopIcon from "@material-ui/icons/Laptop";
import AndroidIcon from "@material-ui/icons/Android";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import AppleIcon from "@material-ui/icons/Apple";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { faLinux } from "@fortawesome/free-brands-svg-icons";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { faAndroid } from "@fortawesome/free-brands-svg-icons";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        position: "relative",
    },
    osIcon: {
        fontSize: 80,
        marginBottom: 5,
        position: "absolute",
        alignSelf: "center",
        color: theme.palette.secondary.main,
    },
    typeIcon: {
        fontSize: 300,

        position: "relative",
        alignSelf: "center",
    },

    osIconSmall: {
        fontSize: 30,
        marginBottom: 5,
        position: "absolute",
        alignSelf: "center",
        color: theme.palette.secondary.main,
    },
    typeIconSmall: {
        fontSize: 120,
        padding: 10,
        position: "relative",
        alignSelf: "center",
    },
}));
const DeviceInconFinder = (props) => {
    const classes = useStyles();
    let { device, size } = props;

    // using clsx to conditional render the name of the class
    const cardOSClass = clsx(
        size == "small" ? classes.osIconSmall : classes.osIcon
    );
    const cardTypeClass = clsx(
        size == "small" ? classes.typeIconSmall : classes.typeIcon
    );

    const getTypeIcon = () => {
        if (device.type == "phone") {
            return (
                <PhoneAndroidIcon className={cardTypeClass} color="primary" />
            );
        } else if (device.type == "laptop") {
            return <LaptopIcon className={cardTypeClass} color="primary" />;
        } else if (device.type == "desktop") {
            return (
                <DesktopWindowsIcon className={cardTypeClass} color="primary" />
            );
        } else if (device.type == "tablet") {
            return (
                <TabletAndroidIcon className={cardTypeClass} color="primary" />
            );
        } else {
            <CancelPresentationIcon
                className={cardTypeClass}
                color="primary"
            />;
        }
    };

    const getOsIcon = () => {
        if (device.os == "apple" || device.os == "mac") {
            return <AppleIcon className={cardOSClass} color="secondary" />;
        } else if (device.os == "android") {
            return (
                <FontAwesomeIcon
                    className={cardOSClass}
                    icon={faAndroid}
                    color="secondary"
                />
            );
        } else if (device.os == "windows") {
            return (
                <FontAwesomeIcon
                    className={cardOSClass}
                    icon={faWindows}
                    color="secondary"
                />
                // <DesktopWindowsIcon  color="secondary" />
            );
        } else if (device.os == "linux") {
            return (
                <FontAwesomeIcon
                    className={cardOSClass}
                    icon={faLinux}
                    color="secondary"
                />
            );
        } else {
            <CancelPresentationIcon
                className={cardOSClass}
                color="secondary"
            />;
        }
    };
    return (
        <div className={classes.root}>
            {getTypeIcon()}
            {getOsIcon()}
        </div>
    );
};

export default DeviceInconFinder;
