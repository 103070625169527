import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { IconButton, Modal, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
    },
    margin: {
        marginRight: theme.spacing(1),
    },
    cancel: {
        marginRight: theme.spacing(1),
        color: "white",
        backgroundColor: theme.palette.error.light,
    },

    action: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        marginRight: theme.spacing(1),
    },
}));

/**
 * 
 * @param {content, buttonType, buttonColor, buttonContext, modal, openModal} props 
 * 
 * @description
 * This is reusabel modal that uses the fade transition
 * 
 * content - will be any compoent (or jsx) that you want to be displayed inside of the modal
 * 
 * buttonType - if you set the button type to 'icon'  it will use the IconButton compoent 
 *  and will be able to pass an icon compoennt in the 'buttonContent' prop to be used as the button
 * else it will be used as the 
 * 
 * 
 * @example
 *  <TransitionsModal
        //content to dispaly inside of the modal
        content={<CreateSystemUser  parentUpdate={getUsers}></CreateSystemUser>}
        //color of the button
        buttonColor="secondary"
        // text to display inside of the button 
        buttonContent={<AddCircleIcon/>}
        // if set to icon you can pass a icon component if not pass jsx like <p>click here to open modal</p>
        buttonType="icon"
        // call an function to update the parent if needed
        parentUpdate={getUsers}
      />
 * 
 */
export default function TransitionsModal(props) {
    const classes = useStyles();

    return (
        <div>
            {props.buttonType === "icon" ? (
                <IconButton
                    className={classes.action}
                    onClick={props.openModal}
                    size="small"
                >
                    {props.buttonContent}
                </IconButton>
            ) : (
                <Button
                    className={classes.action}
                    onClick={props.openModal}
                    size="small"
                >
                    {props.buttonContent}
                </Button>
            )}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                onClose={props.openModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>{props.content}</Fade>
            </Modal>
        </div>
    );
}
