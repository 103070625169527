import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import Echo from "laravel-echo";
import { LoginProvider } from "./context/LoginContext";

window.Pusher = require("pusher-js");
window.Echo = new Echo({
    bradcaster: "pusher",
    key: "*@a%*bVPt%Lv",
    cluster: "mt1",
    wsHost: window.location.hostname,
    wsPort: 6001,
    key: "client",
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    forceTLS: false,
    authEndpoint: "https://example.com/api/broadcasting/auth",
    auth: {
        headers: {
            "XSRF-TOKEN":
                "eyJpdiI6Imx0Sy9aM0h5S1l4MVZpSmEraGZkb2c9PSIsInZhbHVlIjoiWVRrTVU0b082cjlveUxnVGFKaTE2TkJnVUd3MHBIRmxUQko2OEpMQ1lYN3VIOEp1QlIyTmVkbUt3VWF4Mnp5OE9NVGlGQW5PaUxRbFAvclhQNnBiRWhYSWUvQmJRY3VhbEF0NUFJOFVZLzUrRDQrc0IwNXFiemdtSlJqN2Y4UXEiLCJtYWMiOiJjYjE2Yjc5ZmI5ZGY4NmEzNzRkMDFmYWEyZDQxYmZkOGE2ZDYyMjMzMWMzYWIxZTgwZjc4NTdiNzFjYzEyZDBlIn0",
        },
    },
});

ReactDOM.render(
    <HashRouter>
        <App />
    </HashRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
