import React, { useState } from "react";
import { CardContent, Slide, Grid, Typography } from "@material-ui/core";
import { shadows } from "@material-ui/system";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import DeviceInconFinder from "../../DeviceIconFinder/DeviceIconFinder";
import TransitionsModal from "../../Modal/TransitionsModal";
import EditDevice from "../../../Forms/EditDevice/EditDevice";
import EditIcon from "@material-ui/icons/Edit";
import theme from "../../../../themes/nfsDarkTheme";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    "@global": {
        "tbody > div:nth-of-type(1)": {
            display: "block !important",
            backgroundColor: "yellow",
        },
    },
    table: {
        maxWidth: 400,
        boxShadow: theme.shadows[9],
        border: "2px solid grey",
    },
    tableHeader: {
        backgroundColor: "#848080",
    },
    card: {
        position: "relative",
        marginBottom: theme.spacing(3),
    },

    margin: {
        margin: theme.spacing(1),
    },
    edit: {
        position: "absolute",
        top: 60,
        right: 0,
    },
    header: {},
    noLink: {
        listStyle: "none",
        textDecoration: "none",
    },
}));

/**
 * used to display the device icon and some details - majoirty of details are in the DeviceFullDetailCard
 * @param {*} props
 * @returns
 */
const DeviceDetailCard = (props) => {
    const maybePluralize = (count, noun, suffix = "'s") =>
        `${noun}${count !== 1 ? suffix : ""}`;
    const classes = useStyles();
    let { device, client } = props;
    const [modal, setmodal] = useState(false);
    const openModal = () => {
        setmodal(!modal);
    };
    return (
        <div className={classes.card}>
            <CardContent className={classes.deviceInfoCardContent}>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            align="left"
                            color="textSecondary"
                            component={Link}
                            to={`/clients/${client.id}`}
                            className={classes.noLink}
                        >
                            {maybePluralize(0, client.full_name)}
                        </Typography>
                        <Typography
                            variant="h1"
                            align="center"
                            color="textPrimary"
                        >
                            {device.name}
                        </Typography>
                    </Grid>
                    {device.is_active && (
                        <Typography
                            variant="h6"
                            component="h6"
                            align="center"
                            color="textSecondary"
                        >
                            {device.ip_address}
                        </Typography>
                    )}
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                    >
                        <DeviceInconFinder device={device}></DeviceInconFinder>
                    </Grid>
                </Grid>
            </CardContent>
        </div>
    );
};

export default DeviceDetailCard;
