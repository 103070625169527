import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Container, Grid, Paper, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import UserActionsCard from "../../Features/Cards/User/UserActionsCard";
import LoadingPage from "../LoadingPage/LoadingPage";
import UserInformationCard from "../../Features/Cards/User/UserInformationCard";

import LogsTable from "../../Features/Table/LogsTable";
import { withSnackbar } from "../../HOC/SnackbarHOC";
import { LoginContext } from "../../../context/LoginContext";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));
const ProfilePage = (props) => {
    const classes = useStyles();
    const [user, setuser] = useState();
    const [loading, setloading] = useState(true);
    const [logs, setlogs] = useState();
    const [logsPaginate, setlogsPaginate] = useState();
    const [loggedInUser] = useContext(LoginContext);
    const deleteUser = () => {
        let id = loggedInUser.id;
        axios
            .delete(`api/protected/users/${id}`)
            .then((response) => {
                getUser();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const restoreUser = () => {
        let id = user.id;
        axios
            .post(`api/protected/users/${id}/restore`)
            .then((response) => {
                console.log(response);
                getUser();
                getCausedLogs();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getUser = () => {
        axios
            .get(`/api/protected/user`)
            .then((res) => {
                setuser(res.data);
                setloading(false);
                getCausedLogs(res.data.id);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // this enpoint toggle the is_admin property - so, only one neede
    const makeAdmin = () => {
        let id = user.id;
        axios
            .post(`api/protected/users/${id}/admin`)
            .then((res) => {
                getUser();
                getCausedLogs();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getCausedLogs = (id) => {
        axios
            .get(`api/protected/users/${id}/causedlogs`)
            .then((response) => {
                setlogs(response.data.data);
                setlogsPaginate(response.data);
            })
            .catch((error) => {});
    };
    const getPage = (e, page) => {
        // setloading(true);
        let pagePlus = page + 1;
        axios
            .get(logsPaginate.path, {
                params: {
                    page: pagePlus,
                },
            })
            .then((results) => {
                setlogs(results.data.data);
                setlogsPaginate(results.data);
            })
            .catch((error) => {
                setloading(false);
                console.log(error);
            });
    };
    const resetPassword = (e) => {
        let email = loggedInUser.email;

        axios
            .post("/password/email", {
                email,
            })
            .then((response) => {
                props.snackbarShowMessage(
                    `A password reset link has been emaied to you!`
                );
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
                props.snackbarShowMessage(`Opps an error has occured`, `error`);
            });
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <div className={classes.root}>
            {loading ? (
                <LoadingPage></LoadingPage>
            ) : (
                <Grid
                    container
                    spacing={3}
                    justify="space-evenly"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6} md={8}>
                        <UserInformationCard user={user}></UserInformationCard>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <UserActionsCard
                            user={user}
                            deleteUser={deleteUser}
                            restoreUser={restoreUser}
                            makeAdmin={makeAdmin}
                            resetPassword={resetPassword}
                            parentUpdate={() => {
                                getUser();
                            }}
                        ></UserActionsCard>
                    </Grid>
                    {logsPaginate && (
                        <Grid item xs={12}>
                            <LogsTable
                                logs={logs}
                                total={`Total Logs: ${logsPaginate.total}`}
                                logsPaginate={logsPaginate}
                                getPage={getPage}
                            ></LogsTable>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    );
};

export default withSnackbar(ProfilePage);
