import React, { useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import SnackbarAlert from "../../Features/SnackBar/SnackbarAlert";
import { LoginContext } from "../../../context/LoginContext";
import { TextField, InputLabel, FormHelperText } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import SuccessDisplay from "../../Features/Notification/SuccessDisplay";

const useStyles = makeStyles((theme) => ({
    // root: {
    //     width: "90%",
    //     maxWidth: "500px",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     margin: 0,
    //     padding: 0,
    //     minWidth: 400,
    // },
    // form: {
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     padding: 20,
    // },
}));

const ForgotPassword = (props) => {
    let classes = useStyles();
    let history = useHistory();
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [snack, setsnack] = useState(false);
    const [good, setgood] = useState(false);
    const [loggedInUser, setloggedInUser, getUserLoggedIn] =
        useContext(LoginContext);
    const handleChangeEmail = (e) => {
        setemail(e.currentTarget.value);
        console.log(email);
    };
    const handleChangePassword = (e) => {
        setpassword(e.currentTarget.value);
    };

    const submitLogin = (e) => {
        e.preventDefault();
        console.log("email is : " + email + " password is : " + password);

        axios
            .post("/password/email ", {
                email,
            })
            .then((response) => {
                setgood(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <>
            {!good ? (
                <CardContent>
                    <Typography
                        color="secondary"
                        variant="h3"
                        component="h1"
                        style={{ margin: "15px" }}
                        align="center"
                    >
                        Oops!
                    </Typography>
                    <Typography
                        color="secondary"
                        variant="p"
                        component="p"
                        style={{ margin: "15px" }}
                        align="center"
                    >
                        Please call the IT Department for assistance
                    </Typography>

                    {/* <form onSubmit={submitLogin} noValidate>
                            <Typography
                                color="secondary"
                                variant="h6"
                                component="h1"
                                style={{ margin: "15px" }}
                                align="center"
                            >
                                Forgot Password
                            </Typography>
                            {props.error && (
                                <Typography
                                    color="error"
                                    variant="body1"
                                    component="p"
                                    align="center"
                                >
                                    Email is incorrect
                                </Typography>
                            )}

                            <TextField
                                required
                                label="Email Required"
                                fullWidth
                                variant="filled"
                                type="email"
                                onChange={handleChangeEmail}
                                value={email}
                                error={props.error}
                            />

                            <Button
                                color="secondary"
                                variant="contained"
                                size="medium"
                                type="submit"
                                fullWidth
                            >
                                Submit
                            </Button>
                        </form> */}
                </CardContent>
            ) : (
                <SuccessDisplay message="You've been sent an email to reset!" />
            )}
        </>
    );
};

export default ForgotPassword;
