import React, { useContext } from "react";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import LandingPage from "../components/Pages/LandingPage/LandingPage";

import HomePage from "../components/Pages/HomePage/HomePage";
import UsersPage from "../components/Pages/UsersPage/UsersPage";
import NotFoundPage from "../components/Pages/NotFoundPage/NotFoundPage";
import ClientsPage from "../components/Pages/ClientsPage/ClientsPage";
import ClientPage from "../components/Pages/ClientPage/ClientPage";
import DevicePage from "../components/Pages/DevicePage/DevicePage";
import ProfilePage from "../components/Pages/ProfilePage/ProfilePage";
import DevicesPage from "../components/Pages/DevicesPage/DevicesPage";
import UserPage from "../components/Pages/UserPage/UserPage";
import ProtectedRoute from "./ProtectedRoute";
import NonProtectedRoute from "./NonProtectedRoute";
import AdminOnlyRoute from "./AdminOnlyRoute";
import AdminOrUserRoute from "./AdminOrUserRoute";
import { LoginContext } from "../context/LoginContext";

export default (
    <Switch>
        {/*NonProtectedRoute =  if the user is already logged in then it will not allow them to go to the LandingPage, 
        it will redirect the user to the home page  */}
        <NonProtectedRoute exact path="/" component={LandingPage} />
        {/*ProtectedRoute =  if the user is logged in, it will take them to the desired page , else, it will take them to the login page, 
        it will redirect the user to the home page  */}
        <ProtectedRoute exact path="/clients" component={ClientsPage} />
        <ProtectedRoute exact path="/clients/:id" component={ClientPage} />
        <ProtectedRoute
            exact
            path="/clients/:client_id/devices/:device_id"
            component={DevicePage}
        />
        <ProtectedRoute exact path="/devices" component={DevicesPage} />
        <ProtectedRoute path="/home" component={HomePage} />
        <AdminOnlyRoute exact path="/users" component={UsersPage} />
        <AdminOnlyRoute exact path="/users/:user_id" component={UserPage} />
        <ProtectedRoute path="/profile" component={ProfilePage} />

        <Route path="*" component={NotFoundPage} />
    </Switch>
);
