import React, { useState, useEffect } from "react";
import axios from "axios";
import TransitionsModal from "../../Features/Modal/TransitionsModal";
import CreateClient from "../../Forms/CreateClient/CreateClient";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link, useHistory } from "react-router-dom";
import {
    Grid,
    Typography,
    Paper,
    Grow,
    TextField,
    Box,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import sync from "../../../images/insync.svg";
import ClientCard from "../../Features/Cards/Client/ClientCard";
import Pagination from "@material-ui/lab/Pagination";
import LoadingPage from "../LoadingPage/LoadingPage";

const useStyles = makeStyles((theme) => ({
    root1: {
        flexGrow: 1,
    },
    root: {
        backgroundImage: sync,
    },
    title: {
        margin: "2rem",
    },

    searchBar: {
        margin: theme.spacing(3),
    },
    pagination: {
        margin: 15,
    },
}));

const ClientsPage = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [clients, setClients] = useState([]);
    const [modal, setmodal] = useState(false);
    const [searchResults, setsearchResults] = useState([]);
    const [searchError, setsearchError] = useState("");
    const [page, setpage] = useState(1);
    const [paginationDetails, setpaginationDetails] = useState({});
    const [loading, setloading] = useState(false);
    const getClients = (page = 1) => {
        setloading(true);
        axios
            .get("api/protected/clients")
            .then((results) => {
                setpaginationDetails(results.data);
                setloading(false);
                setClients(results.data.data);
                setsearchResults(results.data.data);
            })
            .catch((error) => {
                setloading(false);
                if (error.response.status === 401) {
                    console.log("auth error");
                    history.push("/");
                }
            });
    };
    const handleChange = (event, value) => {
        getPage(value);
    };

    const getPage = (page) => {
        axios
            .get(`api/protected/clients`, {
                params: {
                    page,
                },
            })
            .then((results) => {
                setpaginationDetails(results.data);
                console.log("clients");
                setClients(results.data.data);
                setsearchResults(results.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // const handleSearch = (e, item) => {};

    const handleSearch = (e, item) => {
        !e.target.value && getClients();
        if (e.target.value.length > 2) {
            axios
                .get("api/protected/search/clients", {
                    params: {
                        search: e.target.value,
                        order_by: "first_name",
                    },
                })
                .then((results) => {
                    console.log(results);
                    // console.log(results.data);
                    setpaginationDetails(results.data.search);
                    // console.log("clients");
                    setClients(results.data.search.data);
                    setsearchResults(results.data.search.data);
                })
                .catch();
        }
        // let result = clients.filter((client) => {
        //     if (client.full_name.toLowerCase().includes(value)) {
        //         return client;
        //     } else if (client.email.toLowerCase().includes(value)) {
        //         return client;
        //     } else if (client.phone.toLowerCase().includes(value)) {
        //         return client;
        //     }
        // });
        // if result is empty create error
        // !result && setsearchError("No Results Were found");
        // // setting the result
        // setsearchResults(result);
    };

    useEffect(() => {
        getClients();
    }, []);
    const openModal = () => {
        setmodal(!modal);
    };

    let clientCards = searchResults
        ? searchResults.map((client, i) => {
              return (
                  <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      key={`${client.first_name}-${client.id}`}
                  >
                      <ClientCard
                          client={client}
                          name={`${client.first_name} ${client.last_name}`}
                          learnMore={`/clients/${client.id}`}
                          email={client.email}
                          phone={client.phone}
                          updateList={getClients}
                      />
                  </Grid>
              );
          })
        : "No Results";

    return (
        <div className={classes.root1}>
            {loading ? (
                <LoadingPage></LoadingPage>
            ) : (
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="flex-start"
                    className={classes.root}
                >
                    <Grid item xs={12}></Grid>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={1}>
                            <TransitionsModal
                                content={
                                    <CreateClient
                                        clienId={props.match.params.id}
                                        parentUpdate={getClients}
                                    />
                                }
                                buttonColor="secondary"
                                buttonSize=""
                                buttonContent={
                                    <AddCircleIcon fontSize="large" />
                                }
                                buttonType="icon"
                                parentUpdate={getClients}
                                open={modal}
                                openModal={openModal}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                id="outlined-basic"
                                label="Search"
                                placeholder="Search by names, email, phone"
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                onChange={handleSearch}
                                className={classes.searchBar}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            {/* <Typography variant="body1" align="left">
                                Total Results: {paginationDetails.total}
                            </Typography> */}
                            <Typography variant="body1" align="left">
                                {paginationDetails.from} -{" "}
                                {paginationDetails.to} of{" "}
                                {paginationDetails.total}
                            </Typography>
                        </Grid>
                        {clientCards}
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid item>
                            <Pagination
                                count={
                                    paginationDetails.last_page
                                        ? paginationDetails.last_page
                                        : 0
                                }
                                page={
                                    paginationDetails.current_page
                                        ? paginationDetails.current_page
                                        : 0
                                }
                                onChange={handleChange}
                                className={classes.pagination}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default ClientsPage;
