import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import FaceIcon from "@material-ui/icons/Face";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Link } from "react-router-dom";
import moment from "moment";
import {
    TableCell,
    TableRow,
    Chip,
    Typography,
    Box,
    IconButton,
    Collapse,
    TableContainer,
    Table,
    TableHead,
    TableBody,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    user: {
        backgroundColor: theme.palette.background.paper,
    },
    noLink: {
        textDecoration: "none",
        color: "inherit",
    },
    table: {
        minWidth: 650,
        maxWidth: "100%",
    },
}));
const useRowStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    user: {
        backgroundColor: theme.palette.background.paper,
    },
    noLink: {
        textDecoration: "none",
        color: "inherit",
    },
}));
const LogRow = (props) => {
    const { log } = props;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();
    const findTypIcon = (log) => {
        if (log.subject_type.includes("User")) {
            return (
                <TableCell>
                    <Chip
                        icon={<SupervisedUserCircleIcon />}
                        label="user"
                        className={classes.user}
                        variant="outlined"
                    />
                </TableCell>
            );
        } else if (log.subject_type.includes("Client")) {
            return (
                <TableCell>
                    <Chip
                        icon={<FaceIcon />}
                        label="Client"
                        color="secondary"
                    />
                </TableCell>
            );
        } else if (log.subject_type.includes("Device")) {
            return (
                <TableCell>
                    <Chip
                        icon={<DevicesOtherIcon />}
                        label="Device"
                        color="secondary"
                    />
                </TableCell>
            );
        } else if (log.subject_type.includes("Note")) {
            return (
                <TableCell>
                    <Chip
                        icon={<SpeakerNotesIcon />}
                        label="note"
                        variant="outlined"
                    />
                </TableCell>
            );
        } else {
            return (
                <TableCell>
                    <Chip
                        icon={<FaceIcon />}
                        label="NA"
                        color="primary"
                        variant="outlined"
                    />
                </TableCell>
            );
        }
    };

    // function determines what to display for the created_at/time column. If the created at is the same day it will display hours else the date and time.
    const getCreatedAtFormat = (time) => {
        let now = moment().format("MMM Do YY");
        if (now == moment(time).format("MMM Do YY")) {
            return moment(time).startOf("hour").fromNow();
        } else {
            return moment(time).format("MMMM Do YYYY, h:mm a");
        }
    };

    // loops through current obejct and the previous object and displays the differences in a dropdown component
    const findChanges = (log) => {
        let { properties } = log;
        if (properties["old"]) {
            let { old } = properties;
            let { attributes } = properties;
            let changes = Object.keys(attributes).map((prop, index) => {
                if (attributes[prop] != old[prop]) {
                    if (prop == "password" || prop == "remember_token") {
                        return (
                            <TableRow key={`${index}-changes`}>
                                <TableCell>{prop}</TableCell>
                                <TableCell>protected</TableCell>
                                <TableCell>protected</TableCell>
                            </TableRow>
                        );
                    }
                    if (
                        prop == "created_at" ||
                        prop == "updated_at" ||
                        prop == "deleted_at"
                    ) {
                        return (
                            <TableRow key={`${index}-changes`}>
                                <TableCell>{prop}</TableCell>
                                <TableCell>
                                    {moment(old[prop]).format(
                                        "MMMM Do YYYY, h:mm a"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {moment(attributes[prop]).format(
                                        "MMMM Do YYYY, h:mm a"
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    } else {
                        return (
                            <TableRow key={`${index}-changes`}>
                                <TableCell>{prop}</TableCell>
                                <TableCell>{old[prop]}</TableCell>
                                <TableCell>{attributes[prop]}</TableCell>
                            </TableRow>
                        );
                    }
                }
            });

            return (
                <Table size="small" align="left">
                    <TableHead>
                        <TableRow>
                            <TableCell>Property</TableCell>
                            <TableCell>Was</TableCell>
                            <TableCell>Now</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{changes}</TableBody>
                </Table>
            );
        } else {
            return (
                <TableCell size="small" align="left">
                    No Changes
                </TableCell>
            );
        }
    };

    const findSubjectInfo = (log) => {
        if (log.subject_type.includes("User")) {
            return (
                <TableCell size="small" align="left">
                    <Link
                        to={`/users/${log.subject_id}`}
                        className={classes.noLink}
                    >
                        {log.causer && log.subject.name}
                    </Link>
                </TableCell>
            );
        } else if (log.subject_type.includes("Client")) {
            return (
                <TableCell size="small" align="left">
                    <Link
                        to={`/clients/${log.subject_id}`}
                        className={classes.noLink}
                    >
                        {log.subject.full_name}
                    </Link>
                </TableCell>
            );
        } else if (log.subject_type.includes("Device")) {
            return (
                <TableCell size="small" align="left">
                    <Link
                        to={`/clients/${log.subject.client.id}/devices/${log.subject_id}`}
                        className={classes.noLink}
                    >
                        <Typography variant="body1">
                            {log.subject.name}
                        </Typography>
                        <Typography variant="caption" noWrap>
                            {log.subject.client.full_name}
                        </Typography>
                    </Link>
                </TableCell>
            );
        } else if (log.subject_type.includes("Note")) {
            return <TableCell></TableCell>;
        } else {
            return <TableCell>NA</TableCell>;
        }
    };

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    {log.description.toLowerCase().includes("update") && (
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                            disabled={
                                !log.description
                                    .toLowerCase()
                                    .includes("update")
                            }
                        >
                            {open ? (
                                <KeyboardArrowUpIcon color="secondary" />
                            ) : (
                                <KeyboardArrowDownIcon color="secondary" />
                            )}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell size="small" align="left">
                    {log.id}
                </TableCell>
                <TableCell size="small" align="left">
                    {getCreatedAtFormat(log.created_at)}
                </TableCell>

                <TableCell size="small" align="left">
                    {log.description}
                </TableCell>
                {findTypIcon(log)}
                {findSubjectInfo(log)}
                <TableCell>
                    <Link
                        to={`/users/${log.causer_id}`}
                        className={classes.noLink}
                    >
                        <Chip
                            icon={<SupervisedUserCircleIcon />}
                            className={classes.user}
                            label={log.causer ? log.causer.name : "Na"}
                        />
                    </Link>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>{findChanges(log)}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
export default LogRow;
