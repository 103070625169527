import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import ActiveUserTable from "../../Features/Table/ActiveUserTable";
import InactiveUserTable from "../../Features/Table/InactiveUserTable";
import { Grid, Typography, Paper, Divider } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TransitionsModal from "../../Features/Modal/TransitionsModal";
import CreateSystemUser from "../../Forms/CreateSystemUser/CreateSystemUser";
import FixedHeadersTable from "../../Features/Table/FixedHeadersTable";
import UserCard from "../../Features/Cards/User/UserCard";

import LoadingPage from "../LoadingPage/LoadingPage";
const useStyles = makeStyles((theme) => ({
    addUserModal: {
        alignSelf: "flex-start",
    },
    tableHeader: {
        padding: "15px",
    },

    divider: {
        margin: 15,
    },
}));
const UsersPage = (props) => {
    const classes = useStyles();
    const [user, setuser] = useState({});
    const [nonActiveUser, setnonActiveUser] = useState([{}]);
    const [updateuser, setupdateuser] = useState();
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [name, setname] = useState("");
    const [open, setopen] = useState(false);
    const [loading, setloading] = useState(true);
    const [modal, setmodal] = useState(false);
    const [users, setusers] = useState([{}]);

    // const getUser = () =>{
    //   axios
    //     .get("/api/user")
    //     .then((results) => {
    //     setuser(results.data);
    //     setupdateuser(results.data);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }

    // const removeUser = (user) => {
    //     axios
    //         .delete(`api/protected/users/${user.id}`)
    //         .then((response) => {
    //             getUsers();
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    // const restoreUser = (user) => {
    //     console.log(user);
    //     axios
    //         .post(`api/protected/users/${user.id}/restore`)
    //         .then((response) => {
    //             console.log(response);
    //             getUsers();
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    const getUsers = () => {
        setloading(true);
        axios
            .get("/api/protected/users")
            .then((res) => {
                setusers(res.data.user_active);
                setnonActiveUser(res.data.user_non_active);
                setloading(false);
            })
            .catch((error) => {
                console.log(error);
                setloading(false);
            });
    };

    const openModal = () => {
        setmodal(!modal);
    };

    let userCards =
        users &&
        users.map((user) => {
            return (
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    key={`${user.first_name}-${user.id}`}
                >
                    <UserCard user={user}></UserCard>
                </Grid>
            );
        });

    let inactiveCards =
        nonActiveUser &&
        nonActiveUser.map((user) => {
            return (
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    key={`${user.name}-${user.id}`}
                >
                    <UserCard user={user}></UserCard>
                </Grid>
            );
        });

    useEffect(() => {
        getUsers();
    }, []);
    return (
        <div>
            {loading ? (
                <LoadingPage />
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={3}>
                                <TransitionsModal
                                    content={
                                        <CreateSystemUser
                                            parentUpdate={getUsers}
                                        />
                                    }
                                    buttonColor="secondary"
                                    buttonContent={<AddCircleIcon />}
                                    buttonType="icon"
                                    parentUpdate={getUsers}
                                    open={modal}
                                    openModal={openModal}
                                />
                                <Grid item xs={8}>
                                    <Typography variant="h5">
                                        Active Users
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider
                            color="secondary"
                            className={classes.divider}
                        ></Divider>
                        <Grid container spacing={3}>
                            {userCards && userCards}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Removed Users</Typography>
                        <Divider color="secondary"></Divider>
                        <Grid container spacing={3}>
                            {inactiveCards && inactiveCards}
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                    <Typography
                        variant="h5"
                        component="h2"
                        className={classes.tableHeader}
                    >
                        Active Users
                    </Typography>
                    <ActiveUserTable
                        headers={["ID", "Name", "Email", "Admin"]}
                        rows={users}
                        removeUser={removeUser}
                    />
                </Paper>
            </Grid> */}
                    {/* <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                    <Typography
                        variant="h5"
                        component="h2"
                        className={classes.tableHeader}
                    >
                        Inactive Users
                    </Typography>
                    <InactiveUserTable
                        headers={["ID", "Name", "Email", "Admin"]}
                        rows={nonActiveUser}
                        restoreUser={restoreUser}
                    />
                </Paper>
            </Grid> */}
                </Grid>
            )}
        </div>
    );
};

export default UsersPage;
