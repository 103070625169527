import React, { useState, useEffect } from "react";
import {
    Card,
    CardActions,
    CardHeader,
    Grid,
    IconButton,
    Typography,
} from "@material-ui/core";
import ConfimationAction from "../../ConfirmationAction/ConfirmationAction";
import EmailIcon from "@material-ui/icons/Email";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import EditClient from "../../../Forms/EditClient/EditClient";
import MailIcon from "@material-ui/icons/Mail";
import TransitionsModal from "../../Modal/TransitionsModal";
import DevicesIcon from "@material-ui/icons/Devices";
import CreateDevice from "../../../Forms/CreateDevice/CreateDevice";
const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
    },
    edit: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        padding: 5,
    },
}));

const ClientActionsCard = (props) => {
    let classes = useStyles();
    let { deletClient, restoreClient, active, client, parentUpdate } = props;
    const [modal, setmodal] = useState(false);
    const [editModal, seteditModal] = useState(false);
    const [deviceModal, setdeviceModal] = useState(false);
    const emailClient = () => {
        window.open(`mailto:${client.email}`);
    };

    const openModal = () => {
        setmodal(!modal);
    };

    const openEditModal = () => {
        seteditModal(!editModal);
    };
    const openDeviceModal = () => {
        setdeviceModal(!deviceModal);
    };
    return (
        <Card className={classes.card} raised>
            <CardHeader
                title="Client Actions"
                style={{ textAlign: "center" }}
            ></CardHeader>
            <Grid container alignItems="center" justify="center">
                <Grid item xs={6}>
                    <CardActions>
                        <TransitionsModal
                            content={
                                <EditClient
                                    parentUpdate={parentUpdate}
                                    client={client}
                                    openModal={openEditModal}
                                />
                            }
                            buttonColor="secondary"
                            buttonContent={<EditIcon />}
                            buttonType="icon"
                            parentUpdate={parentUpdate}
                            open={editModal}
                            openModal={openEditModal}
                        />{" "}
                        <Typography></Typography>
                        Edit
                    </CardActions>
                    <CardActions>
                        <TransitionsModal
                            content={
                                <CreateDevice
                                    parentUpdate={parentUpdate}
                                    clientID={client.id}
                                    openModal={openModal}
                                />
                            }
                            buttonColor="secondary"
                            buttonContent={<DevicesIcon />}
                            buttonType="icon"
                            parentUpdate={parentUpdate}
                            open={deviceModal}
                            openModal={openDeviceModal}
                        />
                        Add Device
                    </CardActions>
                </Grid>
                <Grid item xs={6}>
                    <CardActions>
                        <ConfimationAction
                            message="Email"
                            icon={<MailIcon size="small" />}
                            parentAction={() => emailClient()}
                        />
                    </CardActions>
                    <CardActions>
                        {active ? (
                            <ConfimationAction
                                message="Deactivate"
                                question={`Remove client and their devices?`}
                                icon={<DeleteIcon size="small" />}
                                parentAction={() => deletClient()}
                            />
                        ) : (
                            <ConfimationAction
                                message="Restore"
                                icon={
                                    <SettingsBackupRestoreIcon size="small" />
                                }
                                parentAction={() => restoreClient()}
                            />
                        )}
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    );
};

export default ClientActionsCard;
