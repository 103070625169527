import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        position: "relative",
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    actions: {
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
    },
    trash: {
        position: "absolute",
        top: "5px",
        right: "5px",
        "&:hover": {
            color: theme.palette.error.dark,
            backgroundColor: theme.palette.error.light,
        },
    },
    notActive: {
        backgroundColor: "rgba(0,0, 0 , 0.5)",
        color: "transparent",
        textShadow: "0 0 5px rgba(0,0,0,0.5)",
    },
    notActiveStatus: {
        position: "absolute",
        top: 5,
    },
}));

/**
 * used to display a single client , name, phone, email and a link to visit their dedicated page
 * @param {*} props
 * @returns
 */
export default function ClientCard(props) {
    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = match[1] ? "+1 " : "";
            return [
                intlCode,
                "(",
                match[2],
                ") ",
                match[3],
                "-",
                match[4],
            ].join("");
        }
        return null;
    }

    const classes = useStyles();
    let { client, learnMore } = props;

    return (
        <Card
            className={client.is_active ? classes.root : classes.notActive}
            raised
        >
            <CardContent>
                <Typography variant="h5" component="h2" color="textPrimary">
                    {client.first_name} {client.last_name}
                </Typography>
                <Typography color="textSecondary">
                    {formatPhoneNumber(client.phone)}
                </Typography>
                <Typography color="textSecondary">{client.email}</Typography>
            </CardContent>
            <CardActions className={classes.actions}>
                <Typography color="textSecondary">
                    Devices({client.device_count})
                </Typography>
                <Button
                    size="small"
                    component={Link}
                    to={learnMore}
                    color="secondary"
                >
                    Cient Info
                </Button>
                {!client.is_active && (
                    <Typography
                        className={classes.notActiveStatus}
                        color="error"
                        style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                        }}
                    >
                        NotActive
                    </Typography>
                )}
            </CardActions>
        </Card>
    );
}
