import React, { useEffect, useState, useContext } from "react";
import "./App.scss";
import { createMuiTheme } from "@material-ui/core/styles";
import routes from "./routes/routes";
import Nav from "./components/Nav/Nav";
import { ThemeProvider } from "@material-ui/core/styles";
import nfsDarkTheme from "./themes/nfsDarkTheme";
import nfsTheme from "./themes/nfsTheme";
import axios from "axios";
import NoDrawer from "./components/NoDrawer/NoDrawer";
import { useLocation } from "react-router-dom";
import { LoginProvider } from "./context/LoginContext";

import wgLogoLight from "./images/wgLogoLight.svg";
import wgLogo from "./images/wgLogo.svg";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function App() {
    let fullPageRoutes = ["/"];
    let location = useLocation();
    // this gets the current route  of the user and test if should be wrapped in the nav or fhave their own
    let FullPage = fullPageRoutes.includes(location.pathname);
    const [lightTheme, setlightTheme] = useState(
        localStorage.getItem("themeMode") == "false" ? true : false
    );

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: lightTheme ? "#394d6a" : "#0d253f",
                light: "#394d6a",
                dark: "#00001a",
            },
            secondary: {
                main: lightTheme ? "#619d72" : "#90cea1",
                light: " #c2ffd2",
                dark: "#619d72",
            },

            typography: {
                h3: {
                    fontSize: "1.2rem",
                    "@media (min-width:600px)": {
                        fontSize: "1.5rem",
                    },
                },
            },
            type: lightTheme ? "light" : "dark",
            myBackground: lightTheme ? `url(${wgLogo})` : `url(${wgLogoLight})`,
        },

        typography: { useNextVariants: true },
    });

    useEffect(() => {}, []);
    const changeTheme = () => {
        setlightTheme(!lightTheme);

        localStorage.setItem("themeMode", lightTheme);
    };

    return (
        <LoginProvider>
            <ThemeProvider theme={theme}>
                {FullPage ? (
                    <NoDrawer
                        routes={routes}
                        lightTheme={lightTheme}
                        changeTheme={changeTheme}
                    ></NoDrawer>
                ) : (
                    <Nav
                        routes={routes}
                        lightTheme={lightTheme}
                        changeTheme={changeTheme}
                    ></Nav>
                )}
            </ThemeProvider>
        </LoginProvider>
    );
}

export default App;
