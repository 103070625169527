import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import UserAvatar from "../../UserAvatar/UserAvatar";
const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
    },
    edit: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        padding: 5,
    },
    margin: {
        margin: theme.spacing(1),
    },

    avatarContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: 15,
    },
    notActive: {
        backgroundColor: "rgba(0,0, 0 , 0.5)",
        color: "transparent",
        textShadow: "0 0 5px rgba(0,0,0,0.5)",
    },
    notActiveStatus: {
        color: theme.palette.error.main,
    },
    activeStatus: {
        color: theme.palette.success.main,
    },
    customBadge: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        padding: 0,
        borderRadius: "50%",
        height: 25,
        width: 25,
    },
}));

const UserInformationCard = (props) => {
    let classes = useStyles();
    const { user } = props;
    const cardStyle = clsx({
        [classes.notActive]: !user.is_active, //always applies
        [classes.card]: user.is_active, //only when open === true
    });
    const statusStyle = clsx({
        [classes.notActiveStatus]: !user.is_active, //always applies
        [classes.activeStatus]: user.is_active, //only when open === true
    });

    return (
        <div className={cardStyle}>
            <div className={classes.avatarContainer}>
                <UserAvatar user={user} />
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h1" color="textPrimary">
                            {user.name}
                        </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {user.is_admin == 1 ? (
                                <Typography variant="h4">
                                    {" "}
                                    Administrator
                                </Typography>
                            ) : (
                                <Typography variant="h4">
                                    {" "}
                                    Not Administrator
                                </Typography>
                            )}
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                align="left"
                            >
                                {user.email}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Grid container alignItems="center" justify="space-evenly">
                {/* <Grid item xs={12} md={6}></Grid> */}
                {/* <Grid item xs={12} md={6}>
                    <Typography
                        variant="subtitle2"
                        className={classes.margin}
                        color="textSecondary"
                    >
                        Created:{" "}
                        {moment(user.created_at).format(
                            "MMMM Do YYYY, h:mm:ss a"
                        )}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        className={classes.margin}
                        color="textSecondary"
                    >
                        Updated:{" "}
                        {moment(user.updated_at).format(
                            "MMMM Do YYYY, h:mm:ss a"
                        )}
                    </Typography>
                </Grid> */}
            </Grid>
        </div>
    );
};

export default UserInformationCard;
