import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';


const navHeight = '64px';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: `calc(100vh) - ${navHeight}`,

  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    justifyContent: 'space-between'
  },
toolbar: {
display: 'flex',
justifyContent: 'space-between'
},

 content: {
    flexGrow: 1,
 }
 
}));



export default function NoDrawer(props) {
  
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changeTheme = () =>{
    props.changeTheme();
    console.log(props.lightTheme);
  };

  return (
    
    <div className={classes.root}>
      <CssBaseline />
      
      <AppBar
        position="fixed"
        className={classes.appBar}
        >
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarLeft}>
        
              <Typography variant="h6" noWrap>
                NFS VPN
              </Typography>
            </div>
            <div className={classes.toolbarMiddle}>

            </div>
            <div className={classes.toolbarRight}>
              <Switch
              checked={props.lightTheme}
              onChange={changeTheme}
                color="secondary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </div>
        </Toolbar>
      </AppBar> 
  
      <main
     
     className={clsx(classes.content, {
       [classes.contentShift]: open,
     })}
   >
 
     {props.routes}
     {/* <Paper elevation={3} style={{minHeight: 'calc(100vh - 48px)', minWidth: '90%', marginTop: '50px'}}  />
       <Paper /> */}
   </main>
    </div>
  );
}
