import React, { useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import SnackbarAlert from "../../Features/SnackBar/SnackbarAlert";
import { TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "500px",
    },
    form: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "30px",
    },
}));

const CreateSystemUser = (props) => {
    let history = useHistory();
    const [email, setemail] = useState();
    const [name, setname] = useState();
    const [admin, setadmin] = useState(false);
    const [password, setpassword] = useState();
    const [snack, setsnack] = useState(false);
    let [errors, seterrors] = useState(``);
    let classes = useStyles();
    const changePassword = (e) => {
        setpassword(e.currentTarget.value);
    };

    const changeEmail = (e) => {
        setemail(e.currentTarget.value);
    };

    const changeName = (e) => {
        setname(e.currentTarget.value);
    };

    const submitForm = (e) => {
        e.preventDefault();
        axios
            .post("api/protected/users", {
                email,
                name,
                password,
            })
            .then((response) => {
                console.log(response);
                props.parentUpdate && props.parentUpdate();
                setsnack(true);
                setemail("");
                setpassword("");
                setname("");
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <Card className={classes.root}>
            {snack && (
                <SnackbarAlert message="User Has been Created"></SnackbarAlert>
            )}

            <CardContent>
                <form onSubmit={submitForm} noValidate className={classes.form}>
                    <Typography
                        color="secondary"
                        variant="h6"
                        component="h1"
                        style={{ margin: "15px" }}
                        align="center"
                    >
                        Create User
                    </Typography>
                    {/* <Typography color="error" variant="h6" component="p">
            {errorsjsx}
          </Typography> */}
                    <div>
                        <TextField
                            required
                            label="Name Required"
                            fullWidth={true}
                            variant="filled"
                            onChange={changeName}
                        />
                        <TextField
                            required
                            label="Email Required"
                            fullWidth={true}
                            variant="filled"
                            type="email"
                            onChange={changeEmail}
                        />
                        <TextField
                            required
                            label="Password Required"
                            fullWidth={true}
                            type="password"
                            variant="filled"
                            onChange={changePassword}
                        />
                    </div>
                    <Button
                        color="secondary"
                        variant="contained"
                        size="medium"
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </CardContent>
            <CardActions></CardActions>
        </Card>
    );
};

export default CreateSystemUser;
