import React, { useState } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    goodIcon: {
        fontSize: 250,
    },
    goodIconContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        height: 250,
        width: 250,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    textContainer: {
        // backgroundColor: theme.palette.primary.main,
        color: "white",
    },
}));
const SuccessDisplay = (props) => {
    const classes = useStyles();
    const [checked, setChecked] = useState(true);
    return (
        <Slide
            direction="up"
            in={checked}
            mountOnEnter
            unmountOnExit
            timeout={1000}
        >
            <div className={classes.root}>
                <div className={classes.goodIconContainer}>
                    <CheckCircleIcon
                        className={classes.goodIcon}
                        color="secondary"
                    />
                </div>
                <div className={classes.textContainer}>
                    <Typography variant="h3" color="secondary">
                        {props.message}
                    </Typography>
                </div>
            </div>
        </Slide>
    );
};

export default SuccessDisplay;
