import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeviceDetailCard from "../../Features/Cards/Device/DeviceDetailCard";
import { faBuromobelexperte } from "@fortawesome/free-brands-svg-icons";
const useStyles = makeStyles((theme) => ({
    notActive: {
        height: "80vh",
        width: "100%",
    },
}));

const DeviceNotActivatedPage = (props) => {
    let { device, client } = props;
    const classes = useStyles();
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.notActive}
        >
            <div>
                <DeviceDetailCard device={device} client={client} />
                <Typography variant="h6" color="error" align="center">
                    DEVICE IS NO LONGER ACTIVATED
                </Typography>
            </div>
        </Grid>
    );
};

export default DeviceNotActivatedPage;
