import React, { useState, useContext } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    Typography,
    Select,
    InputLabel,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SnackbarAlert from "../../Features/SnackBar/SnackbarAlert";
import SuccessDisplay from "../../Features/Notification/SuccessDisplay";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "350px",
    },
    form: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "30px",
    },
    formControl: {
        minWidth: 120,
    },
}));

const EditDevice = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [snack, setsnack] = useState(false);
    const [errors, seterrors] = useState(``);
    const [name, setname] = useState(props.device.name);
    const [type, settype] = useState(props.device.type);
    const [os, setos] = useState(props.device.os);
    const [good, setgood] = useState(false);

    const editForm = (e) => {
        e.preventDefault();
        axios
            .put(
                `api/protected/clients/${props.client.id}/devices/${props.device.id}`,
                {
                    name,
                    type,
                    os,
                }
            )
            .then((response) => {
                props.parentUpdate && props.parentUpdate();
                setsnack(true);
                setname("");
                setos("");
                settype("");
                setgood(true);
                setTimeout(() => {
                    setsnack(false);
                }, 5000);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <>
            {!good ? (
                <Card className={classes.root}>
                    {snack && (
                        <SnackbarAlert message="Device Has been Updated"></SnackbarAlert>
                    )}
                    <CardContent>
                        <form
                            onSubmit={editForm}
                            noValidate
                            className={classes.form}
                        >
                            <Typography
                                color="secondary"
                                variant="h6"
                                component="h1"
                                style={{ margin: "15px" }}
                                align="center"
                            >
                                Edit Device
                            </Typography>
                            {/* <Typography color="error" variant="h6" component="p">
            {errorsjsx}
          </Typography> */}
                            <div>
                                <FormControl
                                    fullWidth={true}
                                    variant="filled"
                                    className={classes.formControl}
                                >
                                    <TextField
                                        required
                                        label="Device Name"
                                        placeholder="Personal Laptop"
                                        value={name}
                                        fullWidth={true}
                                        variant="filled"
                                        name="name"
                                        onChange={(e) => {
                                            setname(e.currentTarget.value);
                                            console.log(name);
                                        }}
                                    />
                                </FormControl>

                                <FormControl
                                    required
                                    fullWidth={true}
                                    variant="filled"
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor="filled-type-native-simple">
                                        Device
                                    </InputLabel>
                                    <Select
                                        native
                                        value={type}
                                        fullWidth={true}
                                        onChange={(e) =>
                                            settype(e.currentTarget.value)
                                        }
                                        inputProps={{
                                            name: "type",
                                            id: "filled-type-native-simple",
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="phone">Phone</option>
                                        <option value="laptop">Laptop</option>
                                        <option value="desktop">Desktop</option>
                                        <option value="tablet">Tablet</option>
                                    </Select>
                                </FormControl>

                                <FormControl
                                    required
                                    fullWidth={true}
                                    variant="filled"
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor="filled-os-native-simple">
                                        Operating System
                                    </InputLabel>
                                    <Select
                                        native
                                        value={os}
                                        fullWidth={true}
                                        onChange={(e) =>
                                            setos(e.currentTarget.value)
                                        }
                                        inputProps={{
                                            name: "os",
                                            id: "filled-os-native-simple",
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="windows">Windows</option>
                                        <option value="mac">macOS/Ios</option>
                                        <option value="android">Android</option>
                                        <option value="linux">Linux</option>
                                    </Select>
                                </FormControl>
                            </div>
                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </form>
                    </CardContent>
                    <CardActions></CardActions>
                </Card>
            ) : (
                <SuccessDisplay message="Device has been updated" />
            )}
        </>
    );
};

export default EditDevice;
