import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import {
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import UserAvatar from "../../UserAvatar/UserAvatar";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
        position: "relative",
    },

    header: {
        textAlign: "center",
    },
    notActive: {
        backgroundColor: "rgba(0,0, 0 , 0.5)",
        color: "transparent",
        textShadow: "0 0 5px rgba(0,0,0,0.5)",
    },

    avatar: {
        marginRight: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
    },

    avatarContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
    },
    adminChip: {},
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
const UserCard = (props) => {
    const { user } = props;

    const classes = useStyles();
    const cardStyle = clsx({
        [classes.notActive]: !user.is_active, //always applies
        [classes.card]: user.is_active, //only when open === true
    });
    return (
        <Card color="textPrimary" className={cardStyle} raised>
            {/* {!user.is_active  ?  <ConfimationAction message="" icon={<DeleteIcon size="small"/>} parentAction={() => deletuser()} />
    : <ConfimationAction message="Restore" icon={<SettingsBackupRestoreIcon size="small"/>} parentAction={() => restoreuser()} />} */}

            <CardContent>
                <div className={classes.avatarContainer}>
                    {/* <Avatar
                        src="https://randomuser.me/api/"
                        className={classes.avatar}
                    >
                        {user.name && user.name.match(/\b(\w)/g).join("")}
                    </Avatar> */}
                    <UserAvatar user={user} />
                    <div>
                        <Typography
                            variant="h5"
                            component="h2"
                            color="textPrimary"
                        >
                            {user.name}
                        </Typography>

                        <Typography color="textSecondary">
                            {user.email}
                        </Typography>
                    </div>
                    {/* {user.is_admin == 1 && (
                        <Chip
                            variant="outlined"
                            label="Admin"
                            color="secondary"
                            icon={<SupervisorAccountIcon />}
                        />
                    )} */}
                </div>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    component={Link}
                    to={`/users/${user.id}`}
                    color="secondary"
                >
                    User Info
                </Button>
                {!user.is_active && (
                    <Typography color="error" style={{}}>
                        Not Active
                    </Typography>
                )}
            </CardActions>
        </Card>
    );
};

export default UserCard;
