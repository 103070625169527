import React, { useState, useContext } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SnackbarAlert from "../../Features/SnackBar/SnackbarAlert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "350px",
    },
    form: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "30px",
    },
    formControl: {
        minWidth: 120,
    },
    test: {
        color: "red",
    },

    phoneNumberInput: {
        width: "100%",
        backgroundColor: "#DEDEDE",
        border: "none",
    },
}));

const CreateClient = (props) => {
    let history = useHistory();
    const [email, setemail] = useState();
    const [phone, setphone] = useState();
    const [firstName, setfirstName] = useState();
    const [lastName, setlastName] = useState();

    const [snack, setsnack] = useState(false);

    let [errors, seterrors] = useState(``);
    let classes = useStyles();

    const changeFirstName = (e) => {
        setfirstName(e.currentTarget.value);
    };
    const changeLastName = (e) => {
        setlastName(e.currentTarget.value);
    };

    const changeEmail = (e) => {
        setemail(e.currentTarget.value);
    };

    const changePhone = (e) => {
        setphone(e.currentTarget.value);
    };

    const clearForm = () => {
        setemail("");
        setfirstName("");
        setlastName("");
        setphone("");
        seterrors("");

        setsnack(false);
    };

    const submitForm = (e) => {
        e.preventDefault();
        axios
            .post("api/protected/clients", {
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
            })
            .then((response) => {
                console.log(response);
                props.parentUpdate && props.parentUpdate();
                let client = response.data;

                setsnack(true);
                setemail("");
                setphone("");

                setfirstName("");
                setlastName("");

                history.push(`clients/${client.id}`);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <Card className={classes.root}>
            {snack && (
                <SnackbarAlert message="Client Has been Created"></SnackbarAlert>
            )}

            <CardContent>
                <form onSubmit={submitForm} noValidate className={classes.form}>
                    <Typography
                        color="secondary"
                        variant="h6"
                        component="h1"
                        align="center"
                    >
                        Create Client
                    </Typography>

                    <div>
                        <TextField
                            required
                            label="First Name Required"
                            fullWidth={true}
                            variant="filled"
                            name="first_name"
                            value={firstName}
                            onChange={changeFirstName}
                        />
                        <TextField
                            required
                            label="Last Name Required"
                            name="last_name"
                            fullWidth={true}
                            variant="filled"
                            value={lastName}
                            onChange={changeLastName}
                        />

                        <TextField
                            required
                            label="Email Required"
                            fullWidth={true}
                            name="email"
                            variant="filled"
                            type="email"
                            value={email}
                            onChange={changeEmail}
                        />

                        <PhoneInput
                            country={"us"}
                            inputStyle={{
                                width: "100%",
                                backgroundColor: "#5A5A5A",
                                border: "none",
                            }}
                            onlyCountries={["us"]}
                            abel="Email Required"
                            value={phone}
                            variant="filled"
                            specialLabel=""
                            disableDropdown
                            onChange={(phoneInput) => {
                                setphone(phoneInput);
                            }}
                        />
                    </div>
                    <Button
                        color="secondary"
                        variant="contained"
                        size="medium"
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </CardContent>
            <CardActions>
                <Button onClick={clearForm}>Clear</Button>
            </CardActions>
        </Card>
    );
};

export default CreateClient;
