import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  
  root: {
    width: '100%',
   height: 'calc(100vh - 64px)',
    overflowY: 'hidden',
    display: 'flex',
  },
 gridItem1: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
 }
}));

const LoadingPage = (props) => {
    let classes = useStyles();
    return (  
        <div className={classes.root}>
            <Grid  container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    >
                <Grid className={classes.gridItem1} item md={12}>
                <CircularProgress disableShrink color="secondary"/>
               </Grid>
            </Grid> 
        </div>
    );
}
 
export default LoadingPage;