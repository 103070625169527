import React, { useEffect, useState, useContext } from "react";
import "./LandingPage.scss";
import LoginForm from "../../Forms/LoginForm/LoginForm";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Slide, Typography } from "@material-ui/core";
import authUndraw from "../../../images/devices.svg";
import { LoginContext } from "../../../context/LoginContext";
import Zoom from "@material-ui/core/Zoom";
import clsx from "clsx";
import ForgotPassword from "../../Forms/ForgotPassword/ForgotPassword";
import SuccessDisplay from "../../Features/Notification/SuccessDisplay";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "calc(100vh)",

        display: "flex",
    },
    gridItem1: {
        [theme.breakpoints.down("sm")]: {
            display: "absolute",
        },
        height: `100%`,
    },
    gridItem2: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 64px)",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: "transparent",
        },
    },

    picture: {
        width: "80%",
    },

    welcome: {
        height: "100vh",
        width: "50vw",
    },

    pictureBackground: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        minHeight: "100vh",
        padding: "30px",
        display: "flex",
        height: `100%`,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderBottomLeftRadius: "0%",
        borderBottomRightRadius: "0%",
        borderTopRightRadius: " 70%",
        borderTopLeftRadius: " 0%",
        boxShadow: theme.shadows[24],
        backgroundColor: theme.palette.secondary.main,
    },
    zoomDiv: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    successStatus: {
        width: "100%",
        height: "calc(100vh)",

        display: "flex",
    },
    errorStatus: {
        width: "100%",
        height: "calc(100vh)",

        display: "flex",
        // backgroundColor: "#ad4040",
    },

    buttonDiv: {
        width: "100%",
        display: "flex",
        backgroundColor: "red",
    },
    littlePaddingDiv: {
        padding: "15px",
    },
}));

const LandingPage = (props) => {
    let classes = useStyles();
    const [error, seterror] = useState(false);
    const [errorMessage, seterrorMessage] = useState();
    const [success, setsuccess] = useState(false);
    const [successMessage, setsuccessMessage] = useState();
    const [loggedInUser, setloggedInUser, getUserLoggedIn] =
        useContext(LoginContext);
    const statusStyle = clsx({
        [classes.successStatus]: success && !error,
        [classes.errorStatus]: error && !success,
        [classes.root]: !error && !success,
    });
    const handleForgotPassword = () => {
        setloggedInForm(!loggedInForm);
        seterror(false);
        setsuccess(false);
    };
    // bool get toggle if someone presses the forgot password
    const [loggedInForm, setloggedInForm] = useState(true);
    useEffect(() => {}, [loggedInUser]);
    return (
        <div className={statusStyle}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid className={classes.gridItem1} item md={6}>
                    <Paper className={classes.pictureBackground}>
                        <img src={authUndraw} className={classes.picture}></img>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridItem2}>
                    {success && (
                        <SuccessDisplay
                            message={` Hey ${
                                loggedInUser &&
                                loggedInUser.name &&
                                loggedInUser.name.split(" ")[0]
                            }`}
                        />
                    )}
                    <div className={classes.zoomDiv1}>
                        {!success && loggedInForm && (
                            <Zoom in={!success && loggedInForm}>
                                <div className={classes.littlePaddingDiv}>
                                    <LoginForm
                                        setsuccess={setsuccess}
                                        seterror={seterror}
                                        error={error}
                                        seterrorMessage={seterrorMessage}
                                        setsuccessMessage={setsuccessMessage}
                                        login
                                    />
                                    <Button onClick={handleForgotPassword}>
                                        Forgot Password?
                                    </Button>
                                </div>
                            </Zoom>
                        )}
                        {!success && !loggedInForm && (
                            <Zoom in={!success && !loggedInForm}>
                                <div style={{ minWidth: "100%" }}>
                                    <ForgotPassword />
                                    <Button onClick={handleForgotPassword}>
                                        Login
                                    </Button>
                                </div>
                            </Zoom>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default LandingPage;
