import React, { useState, createRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Collapse,
    Typography,
    Backdrop,
    IconButton,
    Popover,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import InfoIcon from "@material-ui/icons/Info";

import FileCopyIcon from "@material-ui/icons/FileCopy";

import EmailTwoToneIcon from "@material-ui/icons/EmailTwoTone";
import DeviceInconFinder from "../../DeviceIconFinder/DeviceIconFinder";

const useStyles = makeStyles((theme) => ({
    root: {},

    media: {
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
    },

    actions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    name: {
        width: "100%",
    },
    cardContent: {
        textAlign: "center",
        margin: 10,
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        opacity: ".5",
        position: "absolute",
        top: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: ".1rem",
    },
}));

/**
 * Card used to display a Device - when cliked will provide more details
 * @param {*} props
 * @returns
 */
export default function DeviceCard(props) {
    const classes = useStyles();
    const [opencollapse, setopencollapse] = useState(false);
    let { device } = props;

    return (
        <Card className={classes.root} raised>
            <CardActionArea>
                <CardContent
                    className={classes.cardContent}
                    onClick={() => setopencollapse(!opencollapse)}
                >
                    <CardMedia className={classes.media}>
                        <DeviceInconFinder device={device} size="small" />
                    </CardMedia>
                    <Typography
                        variant="caption"
                        component="p"
                        className={classes.name}
                    >
                        {device.client.full_name}
                    </Typography>
                    <Typography
                        variant="caption"
                        component="p"
                        className={classes.name}
                    >
                        {device.name}
                    </Typography>
                    <Typography variant="caption" component="p">
                        {device.ip_address ? device.ip_address : "NA"}
                    </Typography>
                    <Backdrop className={classes.backdrop} open={opencollapse}>
                        <Typography variant="caption" component="p">
                            Name: {device.name ? device.name : "NA"}
                        </Typography>
                        <Typography variant="caption" component="p">
                            Ip_address:{" "}
                            {device.ip_address ? device.ip_address : "NA"}
                        </Typography>
                        <Typography variant="caption" component="p">
                            OS: {device.os ? device.os : "NA"}
                        </Typography>
                        <Typography variant="caption" component="p">
                            Type: {device.type ? device.type : "NA"}
                        </Typography>
                        <Typography variant="caption" component="p">
                            Owner:{" "}
                            {device.client ? device.client.full_name : "NA"}
                        </Typography>
                    </Backdrop>
                </CardContent>
            </CardActionArea>
            <CardActions className={classes.actions}>
                <IconButton
                    component={Link}
                    to={`/clients/${device.client_id}/devices/${device.id}`}
                    size="small"
                >
                    <InfoIcon size="small" />
                </IconButton>
            </CardActions>
        </Card>
    );
}
