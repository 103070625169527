import React, { useState, useEffect, useContext } from "react";
import { Grid, Card, Tabs, Tab, AppBar } from "@material-ui/core";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";

import Notes from "../../Features/Cards/NotesCard/NotesCard";
import DeviceActionCard from "../../Features/Cards/Device/DeviceActionsCard";
import DeviceDetailCard from "../../Features/Cards/Device/DeviceDetailCard";
import DeviceFullDetailsCard from "../../Features/Cards/Device/DeviceFullDetailsCard";
import LogsCard from "../../Features/Cards/Logs/LogsCard";
import { LoginContext } from "../../../context/LoginContext";
import { Slide } from "@material-ui/core";
import LogsTable from "../../Features/Table/LogsTable";
import LoadingPage from "../LoadingPage/LoadingPage";
import DeviceNotActivatedPage from "./DeviceNotActivePage";
import { withSnackbar } from "../../HOC/SnackbarHOC";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    card: {
        marginBottom: theme.spacing(3),
    },
    deviceInfoCardContent: {
        // height: 100,
        // display: 'flex',
        // justifyContent: 'center',
    },

    rightContainer: {},

    header: {
        textAlign: "center",
    },

    trash: {
        "&:hover": {
            color: theme.palette.error.dark,
            backgroundColor: theme.palette.error.light,
        },
        padding: 5,
    },

    email: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        padding: 5,
    },
    edit: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        padding: 5,
    },

    deviceContainer: {
        position: "relative",
    },

    margin: {
        margin: theme.spacing(1),
    },

    slideContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
}));

const DevicePage = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setloading] = useState(false);
    const [device, setdevice] = useState({});
    const [alldevices, setalldevices] = useState([]);
    const [client, setclient] = useState({});
    const [expanded, setExpanded] = React.useState(false);
    const [moreDetails, setmoreDetails] = useState(null);
    const [expandedMoreDetails, setExpandedMoreDetails] = React.useState(false);
    const [loggedInUser, setloggedInUser] = useContext(LoginContext);
    const [logs, setlogs] = useState();
    const [logsPaginate, setlogsPaginate] = useState();
    const [tabvalue, settabvalue] = useState(0);
    const [pageLoad, setpageLoad] = useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleExpandMoreDetailsClick = () => {
        setExpandedMoreDetails(!expandedMoreDetails);
    };

    const wgDeviceDetails = () => {
        if (moreDetails == null) {
            axios
                .get(
                    `api/protected/clients/${props.match.params.client_id}/devices/${props.match.params.device_id}/wgdetails`
                )
                .then((response) => {
                    setmoreDetails(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const deleteDevice = () => {
        axios
            .delete(`api/protected/clients/${client.id}/devices/${device.id}`)
            .then((response) => {
                console.log("user has been deleted");
                getDevice();
                props.snackbarShowMessage(`Device has been deleted.`);
                //   waits a couple of seconds to inform the user that the device has been deleted then redirects to client paged
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getDevice = () => {
        if (pageLoad == true) {
            setloading(true);
            setpageLoad(false);
        }
        axios
            .get(
                `api/protected/clients/${props.match.params.client_id}/devices/${props.match.params.device_id}`
            )
            .then((res) => {
                setdevice(res.data.device);
                setclient(res.data.client);

                if (res.data.device.is_active) {
                    wgDeviceDetails();
                }
                setloading(false);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    //place your reentry code
                    history.push("/");
                }
                // if (error.response.status === 404) {
                //     history.push("/pageNotfound");
                // }
            });
    };

    const deleteDeviceNote = (note) => {
        axios
            .delete(`api/protected/devices/${device.id}/notes/${note.id}`)
            .then((response) => {
                // call the device to update the changes

                getDevice();
                props.snackbarShowMessage(`Note has been deleted`);
                // give delete note success response
            })
            .catch((error) => {
                // give delete note error response
            });
    };

    const emailDevice = () => {
        axios
            .post(
                `api/protected/clients/${client.id}/devices/${device.id}/email`
            )
            .then((response) => {
                props.snackbarShowMessage(
                    `Device config has been emailed to client`
                );
            })
            .catch((error) => {
                console.log(error);
                props.snackbarShowMessage(
                    `Oops somthing went wrong! Check client email`,
                    "error"
                );
            });
    };

    const createNote = (note) => {
        let id = loggedInUser.id;
        console.log(`note: ${note} id:${id}`);
        axios
            .post(`api/protected/devices/${device.id}/notes`, {
                note: note,
                user_id: id,
            })
            .then((response) => {
                getDevice();
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const getPage = (e, page) => {
        let pagePlus = page + 1;
        axios
            .get(logsPaginate.path, {
                params: {
                    page: pagePlus,
                },
            })
            .then((results) => {
                setlogs(results.data.data);
                setlogsPaginate(results.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getDeviceLogs = () => {
        axios
            .get(`api/protected/devices/${props.match.params.device_id}/logs`)
            .then((results) => {
                setlogs(results.data.data);
                setlogsPaginate(results.data);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    //place your reentry code
                    history.push("/");
                }
                if (error.response.status === 404) {
                    // history.push("/");
                    console.log(error);
                }
            });
    };

    const tabChange = (event, newValue) => {
        settabvalue(newValue);
    };

    useEffect(() => {
        getDevice();
        getDeviceLogs();
    }, []);

    return (
        <div className={classes.root}>
            {loading ? (
                <LoadingPage />
            ) : (
                <>
                    {device && !device.is_active ? (
                        <DeviceNotActivatedPage
                            device={device}
                            client={client}
                        />
                    ) : (
                        <Grid
                            container
                            justify="center"
                            alignItems="stretch"
                            alignContent="stretch"
                            spacing={2}
                        >
                            <Grid item xs={12} sm={12} md={8}>
                                <DeviceDetailCard
                                    device={device}
                                    client={client}
                                    parentUpdate={getDevice}
                                    moreDetails={moreDetails}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <DeviceActionCard
                                    emailDevice={emailDevice}
                                    deleteDevice={deleteDevice}
                                    client={client}
                                    device={device}
                                    parentUpdate={getDevice}
                                />

                                {moreDetails && device.is_active && (
                                    <DeviceFullDetailsCard
                                        moreDetails={moreDetails}
                                        device={device}
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Card raised>
                                    <AppBar position="static">
                                        <Tabs
                                            value={tabvalue}
                                            onChange={tabChange}
                                            aria-label="simple tabs example"
                                        >
                                            <Tab
                                                label="Notes"
                                                id="simple-tab-notes"
                                                aria-controls="simple-tabpanel-notes"
                                            />
                                            <Tab
                                                label="Logs"
                                                id="simple-tab-logs"
                                                aria-controls="simple-tabpanel-logs"
                                            />
                                        </Tabs>
                                    </AppBar>

                                    <div
                                        role="tabpanel"
                                        hidden={tabvalue !== 0}
                                        id="simple-tabpanel-notes"
                                        aria-labelledby="simple-tab-notes"
                                    >
                                        {tabvalue === 0 && (
                                            <Notes
                                                notes={device.notes}
                                                updateNotes={(note) =>
                                                    createNote(note)
                                                }
                                                deleteNote={(note) =>
                                                    deleteDeviceNote(note)
                                                }
                                                title="Device Notes"
                                            ></Notes>
                                        )}
                                    </div>
                                    <div
                                        role="tabpanel"
                                        hidden={tabvalue !== 1}
                                        id="simple-tabpanel-logs"
                                        aria-labelledby="simple-tab-logs"
                                    >
                                        {tabvalue === 1 && (
                                            <LogsTable
                                                logs={logs}
                                                total={`Total Logs: ${logsPaginate.total}`}
                                                logsPaginate={logsPaginate}
                                                getPage={getPage}
                                            ></LogsTable>
                                        )}
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </div>
    );
};

export default withSnackbar(DevicePage);
