import React, { useState, useEffect, useRef } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Paper,
    setRef,
    Slide,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import ConfimationAction from "../../ConfirmationAction/ConfirmationAction";
import DeleteIcon from "@material-ui/icons/Delete";
import LogsTable from "../../Table/LogsTable";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
    },

    notesContainer: {
        overflow: "scroll",
        width: "100%",
        border: "1px solid",
        borderColor: theme.palette.text.dark,
        borderRadius: 10,
        position: "relative",
    },
}));
const LogsCard = (props) => {
    let classes = useStyles();
    let { logs, getPage, logsPaginate } = props;
    const [checked, setchecked] = useState(true);
    const divRref2 = useRef(null);

    return (
        <Card className={classes.card} raised>
            <CardHeader
                title="Logs"
                style={{ textAlign: "center" }}
            ></CardHeader>

            <CardContent>
                <Typography>{props.total}</Typography>
                <Container className={classes.notesContainer}>
                    <LogsTable
                        logs={logs}
                        logsPaginate={logsPaginate}
                        getPage={getPage}
                    ></LogsTable>
                </Container>
            </CardContent>
        </Card>
    );
};

export default LogsCard;
