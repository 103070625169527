import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Typography from "@material-ui/core/Typography";
import TabletAndroidIcon from "@material-ui/icons/TabletAndroid";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LaptopIcon from "@material-ui/icons/Laptop";
import AndroidIcon from "@material-ui/icons/Android";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import AppleIcon from "@material-ui/icons/Apple";
import Slide from "@material-ui/core/Slide";
import Collapse from "@material-ui/core/Collapse";

import { CardActions } from "@material-ui/core";
import OsCard from "./OsCard";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        padding: 10,
    },
    contain: {
        alignSelf: "center",
        padding: 5,
        overflow: "hidden",
    },
    typeIcon: {
        fontSize: 80,
        color: theme.palette.secondary.main,
        position: "absolute",
        left: -25,
        bottom: 0,
        alignSelf: "left",
    },

    total: {
        fontSize: 45,
        zIndex: 2,
    },
    type: {
        fontSize: 15,
        zIndex: 2,
    },
    osCardDiv: {
        display: "flex",
    },
}));

export default function TypeCard(props) {
    const { kpi } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [checked, setChecked] = useState(true);
    const [openCollapse, setopenCollapse] = useState(false);

    const getTypeIcon = () => {
        if (kpi.type == "phone") {
            return (
                <PhoneAndroidIcon
                    className={classes.typeIcon}
                    color="primary"
                />
            );
        } else if (kpi.type == "laptop") {
            return <LaptopIcon className={classes.typeIcon} color="primary" />;
        } else if (kpi.type == "desktop") {
            return (
                <DesktopWindowsIcon
                    className={classes.typeIcon}
                    color="primary"
                />
            );
        } else if (kpi.type == "tablet") {
            return (
                <TabletAndroidIcon
                    className={classes.typeIcon}
                    color="primary"
                />
            );
        } else {
            <CancelPresentationIcon
                className={classes.typeIcon}
                color="primary"
            />;
        }
    };

    return (
        <div>
            <Card
                className={classes.root}
                onClick={() => setopenCollapse(!openCollapse)}
                raised
            >
                <Slide
                    direction="right"
                    in={checked}
                    mountOnEnter
                    unmountOnExit
                    timeout={2000}
                >
                    {getTypeIcon()}
                </Slide>
                <CardContent className={classes.contain}>
                    <Typography
                        variant="caption"
                        className={classes.total}
                        color="textSecondary"
                    >
                        {kpi.total}
                    </Typography>
                    <Typography
                        variant="caption"
                        className={classes.type}
                        color="textSecondary"
                    >
                        {kpi.type}
                    </Typography>
                </CardContent>
            </Card>
            <Collapse in={openCollapse}>
                <div className={classes.osCardDiv}>
                    {kpi.breakdown &&
                        kpi.breakdown.map((os, index) => (
                            <OsCard
                                kpi={os}
                                key={`${kpi.type}-${os.type}-${index}`}
                            />
                        ))}
                </div>
            </Collapse>
        </div>
    );
}
