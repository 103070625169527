import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Card, CardContent, Typography} from '@material-ui/core';
import team from "../../../images/team.svg";

const useStyles = makeStyles((theme) => ({
  
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    overflow: 'hidden',
    backgroundImage: `url(${team})`,
        backgroundPosition: 'center', 
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat'
    
  },


}));

const NotFoundPage = (props) => {
    let classes = useStyles();
    return (  
        <div className={classes.root}>
            <Grid  container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    >
           
                    {/* <img src={team}></img> */}
                    <div>

      <Typography variant="h1" component="h1">
           404
        </Typography>
        <Typography variant="h5" component="h2">
            Oops! This Page Is not available.
        </Typography>
                    </div>
                {/* <Card className={classes}>
      <CardContent>
      </CardContent>
      
    </Card> */}

          
            </Grid> 
        </div>
    );
}
 
export default NotFoundPage;