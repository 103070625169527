import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Backdrop, Card, Modal } from "@material-ui/core";
import TransitionsModal from "../../Modal/TransitionsModal";
import CreateDevice from "../../../Forms/CreateDevice/CreateDevice";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",

        alignItems: "center",
        justifyContent: "center",
    },

    card: {
        height: 260,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:active": {
            textDecoration: "none",
            transform: "scale(0.9)",
        },
    },
}));

/**
 *  actions card used to popup the createDevice form
 * @param {*} props
 * @returns
 */
const DeviceAddCard = (props) => {
    const [modal, setmodal] = useState(false);
    const [open, setopen] = useState(false);
    const { parentUpdate, client } = props;
    const classes = useStyles();
    const handleOpen = () => {
        setopen(true);
    };

    const handleClose = () => {
        setopen(false);
    };
    return (
        <>
            <Card raised className={classes.card} onClick={handleOpen}>
                <AddCircleIcon fontSize="large" color="secondary" />
            </Card>
            <Modal
                aria-labelledby="Add-device"
                aria-describedby="add-device-to-client-list"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <CreateDevice
                        parentUpdate={parentUpdate}
                        clientID={client.id}
                        openModal={handleClose}
                    />
                </Fade>
            </Modal>
        </>
    );
};

export default DeviceAddCard;
