import React, { useState, useEffect } from "react";
import { Button, Card, CardActions, CardHeader, Grid } from "@material-ui/core";
import ConfimationAction from "../../ConfirmationAction/ConfirmationAction";
import EmailIcon from "@material-ui/icons/Email";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import TransitionsModal from "../../Modal/TransitionsModal";
import EditDevice from "../../../Forms/EditDevice/EditDevice";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
    },
    edit: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        padding: 5,
    },
    header: {
        // backgroundColor: theme.palette.primary.main,
    },
}));

/**
 * Actios card for the devices where the user can do anything to manipulte the state of the device
 * @param {*} props
 * @returns
 */
const DeviceActionCard = (props) => {
    let classes = useStyles();
    let { emailDevice, deleteDevice, client, device, parentUpdate } = props;
    const [modal, setmodal] = useState(false);

    const openModal = () => {
        setmodal(!modal);
    };
    return (
        <Card className={classes.card} raised>
            <CardHeader
                title="Device Actions"
                style={{ textAlign: "center" }}
                className={classes.header}
            ></CardHeader>
            <Grid container alignItems="center" justify="center">
                <Grid item xs={6}>
                    <CardActions variant="contained" color="">
                        <Button component={Link} to={`/clients/${client.id}`}>
                            <EmojiPeopleIcon></EmojiPeopleIcon>
                            Client Page
                        </Button>
                    </CardActions>
                    <CardActions>
                        <TransitionsModal
                            content={
                                <EditDevice
                                    parentUpdate={parentUpdate}
                                    client={client}
                                    device={device}
                                />
                            }
                            buttonColor="secondary"
                            buttonContent={<EditIcon />}
                            buttonType="icon"
                            parentUpdate={parentUpdate}
                            open={modal}
                            openModal={openModal}
                        />{" "}
                        Edit
                    </CardActions>
                </Grid>
                <Grid item xs={6}>
                    <CardActions>
                        <ConfimationAction
                            message="Delete Device"
                            icon={<DeleteIcon size="small" />}
                            parentAction={deleteDevice}
                            question="Delete the device?"
                        />
                    </CardActions>
                    <CardActions variant="contained" color="">
                        <ConfimationAction
                            message="Email Config"
                            icon={<EmailIcon size="small" />}
                            parentAction={emailDevice}
                            question="Email Client the config?"
                        />
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    );
};

export default DeviceActionCard;
