import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";

import PeopleIcon from "@material-ui/icons/People";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListIcon from "@material-ui/icons/List";
import Switch from "@material-ui/core/Switch";
import { useLocation } from "react-router-dom";
import { Paper, Container } from "@material-ui/core";
import DevicesIcon from "@material-ui/icons/Devices";
import { Link, useHistory } from "react-router-dom";
import dots from "../../images/team.svg";

import wgLogoLight from "../../images/wgLogoLight.svg";
import backdrop from "../../images/backdrop.svg";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { LoginContext } from "../../context/LoginContext";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import axios from "axios";
const drawerWidth = 240;
const navHeight = "64px";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        minHeight: `calc(100vh) - ${navHeight}`,
    },
    home: {
        color: "white",
        textDecoration: "none",
        "&:hover, &:focus, &:active, &:visited": {
            color: "white",
            textDecoration: "none",
        },
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        justifyContent: "space-between",
    },

    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
    },

    toolbarLeft: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    toolbarMiddle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    toolbarRight: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundImage: `url(${dots})`,
        backgroundPosition: "bottom",
        backgroundSize: "fill",
        backgroundRepeat: "no-repeat",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        marginTop: 0,
        // backgroundImage: `url(${backdrop})`,
        // backgroundPosition: "center",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },

    mainContainer: {
        padding: theme.spacing(3),
        minHeight: "94vh",
        boxShadow: theme.shadows[24],
    },
    backdrop: {
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "fff",
        minHeight: "100vh",

        backgroundColor: theme.palette.primary.main,
    },
}));

export default function Nav(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [loggedInUser, setloggedInUser, loginUser, logoutUser] = useContext(
        LoginContext
    );
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const changeTheme = () => {
        props.changeTheme();
        console.log(props.lightTheme);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar className={classes.toolbar}>
                    <div className={classes.toolbarLeft}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(
                                classes.menuButton,
                                open && classes.hide
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            className={classes.home}
                            variant="h6"
                            component={Link}
                            to="/home"
                        >
                            NFS VPN
                        </Typography>
                    </div>
                    <div className={classes.toolbarMiddle}></div>
                    <div className={classes.toolbarRight}>
                        <Switch
                            checked={props.lightTheme}
                            onChange={changeTheme}
                            color="secondary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <ListItem button component={Link} to={`/profile`}>
                        <ListItemIcon>
                            {" "}
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={loggedInUser.name} />
                    </ListItem>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button component={Link} to="/home">
                        <ListItemIcon>
                            {" "}
                            <EqualizerIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button component={Link} to="/clients">
                        <ListItemIcon>
                            {" "}
                            <EmojiPeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Clients" />
                    </ListItem>
                    <ListItem button component={Link} to="/devices">
                        <ListItemIcon>
                            {" "}
                            <DevicesIcon />
                        </ListItemIcon>
                        <ListItemText primary="Devices" />
                    </ListItem>
                </List>
                {loggedInUser && loggedInUser.is_admin === 1 && (
                    <>
                        <Divider />
                        <List>
                            <ListItem button component={Link} to="/users">
                                <ListItemIcon>
                                    {" "}
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>
                        </List>
                    </>
                )}
                <Divider />
                <List>
                    {/* <ListItem button component={Link} to={`/profile`}>
                        <ListItemIcon>
                        {" "}
                        <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={loggedInUser.name} />
                    </ListItem> */}
                    <ListItem
                        button
                        onClick={() => {
                            logoutUser();
                        }}
                    >
                        <ListItemIcon>
                            {" "}
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
                <div className={classes.backdrop} />
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                <Container
                    maxWidth="lg"
                    component={Paper}
                    className={classes.mainContainer}
                >
                    {props.routes}
                </Container>
            </main>
        </div>
    );
}
