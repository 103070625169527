import React, { useState, useEffect } from "react";
import { Grid, Typography, Card, Avatar } from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
        position: "relative",
    },
    edit: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        padding: 5,
    },
    margin: {
        margin: theme.spacing(1),
    },
    avatar: {
        marginRight: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
    },

    avatarContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: 15,
    },
    iconDiv: {
        position: "absolute",
        top: 4,
        right: 4,
        display: "flex",
        alignItems: "center",
    },
}));

const ClientDetailCard = (props) => {
    const classes = useStyles();
    const { client } = props;

    /**
     *Used to properly display a clients phonenumber
     * @param {*} phoneNumberString
     * @returns formated phonenumber
     */
    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = match[1] ? "+1 " : "";
            return [
                intlCode,
                "(",
                match[2],
                ") ",
                match[3],
                "-",
                match[4],
            ].join("");
        }
        return null;
    }

    return (
        <div className={classes.card}>
            <div className={classes.avatarContainer}>
                <div>
                    <Typography variant="h1" color="textPrimary">
                        {client.full_name}{" "}
                    </Typography>
                    <Typography variant="h4" color="textSecondary">
                        {client.email}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {formatPhoneNumber(client.phone)}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default ClientDetailCard;
