import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    notActive: {
        height: "80vh",
        width: "100%",
    },
}));

const UserNotActivatedPage = (props) => {
    let { user, restoreUser } = props;
    const classes = useStyles();
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.notActive}
        >
            <Typography component="h2" variant="h3" color="textSecondary">
                {user.name} has been deactivated
            </Typography>

            <Typography component="h2" variant="h6" color="textSecondary">
                Would like to restore this user?
            </Typography>
            <Button variant="contained" color="primary" onClick={restoreUser}>
                Restore
            </Button>
        </Grid>
    );
};

export default UserNotActivatedPage;
