import { Badge, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
const useStyles = makeStyles((theme) => ({
    customBadge: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        padding: 0,
        borderColor: theme.palette.primary.main,
        borderRadius: "50%",
        height: 25,
        width: 25,
        border: "1px solid",
        borderColor: theme.palette.secondary.main,
    },
    avatar: {
        marginRight: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor: theme.palette.secondary.main,
        // color: theme.palette.text.light,
    },
}));
const UserAvatar = (props) => {
    let { user } = props;
    let classes = useStyles();

    return (
        <Badge
            overlap="circle"
            invisible={!user.is_admin}
            classes={{ badge: classes.customBadge }}
            badgeContent={<SupervisedUserCircleIcon fontSize="large" />}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
        >
            <Avatar src="https://randomuser.me/api/" className={classes.avatar}>
                {user.name && user.name.match(/\b(\w)/g).join("")}
            </Avatar>
        </Badge>
    );
};

export default UserAvatar;
