import React, { useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import GroupIcon from "@material-ui/icons/Group";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";

import { Link } from "react-router-dom";
import { LoginContext } from "../../../../context/LoginContext";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        textDecoration: "none",

        "&:focus": {
            textDecoration: "none",
        },
        "&:hover": {
            textDecoration: "none",
        },
        "&:visited": {
            textDecoration: "none",
        },
        "&:link": {
            textDecoration: "none",
        },
        "&:active": {
            textDecoration: "none",
            transform: "scale(0.9)",
        },
    },
    details: {
        display: "flex",
        flexDirection: "column",
    },
    content: {
        flex: "1 0 auto",
    },
    cover: {
        width: 151,
    },

    header: {
        color: theme.palette.text.secondary,
    },

    number: {
        fontSize: 25,
        color: theme.palette.text.secondary,
    },
    inactive: {
        fontSize: 12,
        color: theme.palette.text.secondary,
    },

    deviceIcon: {
        width: "100%",
        height: 80,
        color: theme.palette.secondary.main,
    },
    clientIcon: {
        width: "100%",
        height: 80,
        color: theme.palette.secondary.main,
    },
    usersIcon: {
        width: "100%",
        height: 80,
        color: theme.palette.secondary.main,
    },
}));

/**
 * Card used to display key perfomance indicators
 * for the app. As of now this displays the Devices,
 * Clients and Users - how many their are and how many are removed
 *  note that these alos act as links to certain pages in the application
 * and depending ont he user admin rights may not be allowwed to viseit such link
 * this is handlee in the getLink method
 *
 * @param {*} props
 * @returns
 */
export default function KpiCard(props) {
    const { kpi } = props;
    const classes = useStyles();
    const theme = useTheme();

    const [loggedInUser] = useContext(LoginContext);

    /**
     * returns an icon appropriate to the type of kpi
     *
     * @returns
     */
    const getIcon = () => {
        if (kpi.type == "device") {
            return <DevicesOtherIcon className={classes.deviceIcon} />;
        } else if (kpi.type == "client") {
            return <EmojiPeopleIcon className={classes.clientIcon} />;
        } else if (kpi.type == "user") {
            return <GroupIcon className={classes.usersIcon} />;
        }
    };

    /**
     * returs a link so the user can navigate the app via the cards
     * only if a user is an admin will allow them to viist the Users page
     * @returns
     */
    const getLink = () => {
        if (kpi.type == "device") {
            return "/devices";
        } else if (kpi.type == "client") {
            return "/clients";
        } else if (kpi.type == "user") {
            // check to see if user has permission to access the users page
            // if not redirect them home
            if (loggedInUser.is_admin == 0) {
                return "/home";
            }
            return "/users";
        }
    };
    return (
        <Card className={classes.root} raised component={Link} to={getLink}>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography
                        component="h5"
                        variant="h4"
                        className={classes.header}
                    >
                        {kpi.title}
                    </Typography>
                    <Typography variant="caption" className={classes.number}>
                        {kpi.active_count}
                    </Typography>
                    <br></br>
                    <Typography variant="caption" className={classes.inactive}>
                        Removed {kpi.inactive_count}
                    </Typography>
                </CardContent>
                <div className={classes.stats}></div>
            </div>
            <CardMedia className={classes.cover}>{getIcon()}</CardMedia>
        </Card>
    );
}
