import React, { useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import SnackbarAlert from "../../Features/SnackBar/SnackbarAlert";
import { TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import SuccessDisplay from "../../Features/Notification/SuccessDisplay";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "500px",
    },
    form: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "30px",
    },
}));

const EditUser = (props) => {
    let { user } = props;
    let history = useHistory();
    const [email, setemail] = useState(user.email);
    const [name, setname] = useState(user.name);
    const [good, setgood] = useState(false);
    const [snack, setsnack] = useState(false);
    let [errors, seterrors] = useState(``);
    let classes = useStyles();

    const changeEmail = (e) => {
        setemail(e.currentTarget.value);
    };

    const changeName = (e) => {
        setname(e.currentTarget.value);
    };

    const submitForm = (e) => {
        e.preventDefault();
        axios
            .put(`api/protected/users/${user.id}`, {
                email,
                name,
            })
            .then((response) => {
                props.parentUpdate();
                setgood(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <>
            {!good ? (
                <Card className={classes.root} raised>
                    <CardContent>
                        <form
                            onSubmit={submitForm}
                            noValidate
                            className={classes.form}
                        >
                            <Typography
                                color="secondary"
                                variant="h6"
                                component="h1"
                                style={{ margin: "15px" }}
                                align="center"
                            >
                                Edit User
                            </Typography>
                            {/* <Typography color="error" variant="h6" component="p">
            {errorsjsx}
          </Typography> */}
                            <div>
                                <TextField
                                    required
                                    fullWidth
                                    variant="filled"
                                    onChange={changeName}
                                    value={name}
                                />
                                <TextField
                                    required
                                    fullWidth
                                    variant="filled"
                                    type="email"
                                    onChange={changeEmail}
                                    value={email}
                                />
                            </div>
                            <Button
                                color="secondary"
                                variant="contained"
                                size="medium"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            ) : (
                <SuccessDisplay message="User has been updated!" />
            )}
        </>
    );
};

export default EditUser;
