import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { faLinux } from "@fortawesome/free-brands-svg-icons";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { faAndroid } from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    details: {
        display: "flex",
        flexDirection: "column",
    },

    number: {
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    cover: {
        padding: 10,
        display: "flex",
        alignItems: "center",
    },
    icon: {
        fontSize: 20,
        margin: 10,
        color: theme.palette.secondary.dark,
    },

    apple: {
        color: "#666666",
    },

    windows: {
        color: "#007FD5",
    },
    linux: {
        color: "black",
    },

    android: {
        color: "#9FC437",
    },
}));

/**
 * Used to diplay Device OS stats
 * @param {} props
 * @returns
 */
export default function OsCard(props) {
    const { kpi } = props;
    const classes = useStyles();
    const theme = useTheme();
    const getOsIcon = () => {
        if (kpi.os == "apple" || kpi.os == "mac") {
            return (
                <FontAwesomeIcon
                    className={`${classes.icon} ${classes.apple}`}
                    icon={faApple}
                    color="secondary"
                />
            );
        } else if (kpi.os == "android") {
            return (
                <FontAwesomeIcon
                    className={`${classes.icon} ${classes.android}`}
                    icon={faAndroid}
                    color="secondary"
                />
            );
        } else if (kpi.os == "windows") {
            return (
                <FontAwesomeIcon
                    className={`${classes.icon} ${classes.windows}`}
                    icon={faWindows}
                    color="secondary"
                />
                // <DesktopWindowsIcon  color="secondary" />
            );
        } else if (kpi.os == "linux") {
            return (
                <FontAwesomeIcon
                    className={`${classes.icon} ${classes.linux}`}
                    icon={faLinux}
                    color="secondary"
                />
            );
        } else {
            <CancelPresentationIcon
                className={(classes.cancel, classes.icon)}
                color="secondary"
            />;
        }
    };
    return (
        <Card className={classes.root} raised>
            <CardMedia className={classes.cover}>
                {getOsIcon()}{" "}
                <Typography variant="caption" className={classes.number}>
                    {kpi.total}
                </Typography>
            </CardMedia>
            <div className={classes.details}>
                <CardContent className={classes.content}></CardContent>
            </div>
        </Card>
    );
}
