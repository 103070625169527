import React, {
    useState,
    useEffect,
    useRef,
    useContext,
    createRef,
} from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Paper,
    setRef,
    Slide,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import ConfimationAction from "../../ConfirmationAction/ConfirmationAction";
import DeleteIcon from "@material-ui/icons/Delete";
import { LoginContext } from "../../../../context/LoginContext";
import UserAvatar from "../../UserAvatar/UserAvatar";
const AlwaysScrollToBottom = () => {
    const elementRef = useRef(null);
    useEffect(() => {
        elementRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
        });
    });
    return <div ref={elementRef} />;
};

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
        position: "relative",
    },
    noteBox: {
        width: "100%",
        borderRadius: 10,

        padding: theme.spacing(1),
    },

    notesContainer: {
        overflow: "scroll",
        width: "100%",
        border: "1px solid",
        borderColor: theme.palette.text.dark,
        borderRadius: 10,
        position: "relative",
        height: 300,
    },
    textArea: {
        position: "relative",
        width: "100%",
        maxWidth: 420,
        borderRadius: 5,
        borderColor: theme.palette.text.light,
    },

    noteDeleted: {
        backgroundColor: theme.palette.error.main,
    },
    form: {
        margin: theme.spacing(3),
        width: "100%",
        maxWidth: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
        },
    },

    avatarDiv: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        flexWrap: "wrap",
        padding: theme.spacing(1),
    },
    trash: {
        marginLeft: "auto",
    },

    avatar: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        marginRight: 5,
    },

    chatBubble: {
        padding: 10,
        borderRadius: 15,
    },

    submitButton: {
        alignSelf: "flex-end",
        margin: 20,
    },
    refDiv: {
        height: 1,
    },
}));
const Notes = (props) => {
    let classes = useStyles();
    let { notes, updateNotes, deleteNote, title } = props;
    const [checked, setchecked] = useState(true);
    const [note, setnote] = useState("");
    const [loggedInUser, setloggedInUser] = useState(LoginContext);
    const divRref = useRef(null);

    const handleChange = (e) => {
        setnote(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateNotes(note);
        setnote("");
    };

    const handleDeleteNote = (note) => {
        deleteNote(note);
    };

    const pullDown = () => {
        // setTimeout(() => {
        //     divRref.current.scrollIntoView({
        //         behavior: "smooth",
        //         block: "nearest",
        //         inline: "start",
        //     });
        // }, 500);
    };
    // useEffect(() => {
    //     pullDown();
    // }, [notes]);

    const renderNotes =
        notes &&
        notes.map((note, key) => {
            //regex to break up each word in the name, then grap first letter and put into array, need to join array below
            let initals = note.user.name.match(/\b(\w)/g);
            return (
                <Slide
                    direction="up"
                    in={checked}
                    mountOnEnter
                    unmountOnExit
                    timeout={1000}
                    key={`${key}_note`}
                >
                    <Box className={classes.noteBox}>
                        <div className={classes.avatarDiv}>
                            <UserAvatar user={note.user} size="" />
                            {/* <Avatar className={classes.avatar}>
                                {initals.join("")}
                            </Avatar> */}
                            <div>
                                <Typography variant="caption" component="span">
                                    {note.user.name} -{" "}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="caption" component="span">
                                    {" "}
                                    {`${moment(note.created_at).format(
                                        "MMM Do YY, h:mm:ss a"
                                    )} `}{" "}
                                </Typography>
                            </div>

                            <div className={classes.trash}>
                                <ConfimationAction
                                    message=""
                                    icon={<DeleteIcon size="small" />}
                                    parentAction={() => handleDeleteNote(note)}
                                />
                            </div>
                        </div>
                        <Paper className={classes.chatBubble}>
                            <Typography variant="body1">{note.note}</Typography>
                        </Paper>
                    </Box>
                </Slide>
            );
        });
    return (
        <Card className={classes.card}>
            <CardHeader
                title={title}
                style={{ textAlign: "center" }}
            ></CardHeader>
            <CardContent>
                <Container className={classes.notesContainer}>
                    {/* <Box style={{ height: "300px" }}> */}
                    {renderNotes}
                    {/* this div is used as a dummy div to make sure the user reads the most recent notes at the bottom  of the div */}
                    <AlwaysScrollToBottom />
                    {/* </Box> */}
                </Container>
                <form onSubmit={handleSubmit} className={classes.form}>
                    <TextareaAutosize
                        aria-label="minimum height"
                        rowsMin={3}
                        placeholder="Create Note"
                        className={classes.textArea}
                        onChange={handleChange}
                        value={note}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className={classes.submitButton}
                    >
                        Submit
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default Notes;
