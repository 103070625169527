import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: green,
    type: 'light'  
},
});
export default theme;