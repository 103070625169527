import React, { useState, useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { LoginContext } from "../context/LoginContext";
import LoadingPage from "../components/Pages/LoadingPage/LoadingPage";
import axios from "axios";
const AdminOnlyRoute = ({ component: Component, ...rest }) => {
    const [loggedInUser, setloggedInUser] = useContext(LoginContext);

    const [auth, setauth] = useState(false);
    const [loading, setloading] = useState(true);
    useEffect(() => {
        const asyncFunc = async () => {
            const response = await axios.get("/api/protected/user");
            return response;
        };
        let sss = asyncFunc();
        sss.then((res) => {
            if (res.data.is_admin) {
                setauth(true);

                setloggedInUser(res.data);
            } else {
                setauth(false);
                setloggedInUser(res.data);
            }
            setloading(false);
        }).catch((error) => {
            setloading(false);
        });
    }, []);
    return loading ? (
        <LoadingPage />
    ) : (
        <Route
            {...rest}
            render={(props) => {
                if (auth) {
                    return <Component {...props} />;
                }
                return <Redirect to="/notfound" />;
            }}
        ></Route>
    );
};

export default AdminOnlyRoute;
