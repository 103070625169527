import React, { useState, useEffect } from "react";
import { Grid, Typography, IconButton, Card, Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    margin: {
        marginRight: theme.spacing(1),
    },
    cancel: {
        marginRight: theme.spacing(1),
        color: "white",
        backgroundColor: theme.palette.error.light,
    },

    action: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
    },

    slideContainer: {
        padding: 0,
        margin: 0,
    },
    tinyText: {
        fontSize: 12,
    },
}));
const ConfimationAction = (props) => {
    let classes = useStyles();
    const [checked, setChecked] = useState(false);
    const handleChange = () => {
        setChecked(!checked);
    };

    const handleForm = () => {
        setChecked(false);
        props.parentAction();
    };
    return (
        <Grid container>
            {!checked && (
                <Grid item xs={12}>
                    <IconButton
                        className={classes.action}
                        onClick={handleChange}
                        size="small"
                    >
                        {props.icon}
                    </IconButton>{" "}
                    {props.message}
                </Grid>
            )}
            <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
                <Grid container className={classes.slideContainer}>
                    <Grid item xs={12}>
                        <Typography className={classes.tinyText}>
                            {props.question}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleChange}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={handleForm}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Slide>
        </Grid>
    );
};

export default ConfimationAction;
