import React, { useState, useEffect, useContext } from "react";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    ListItem,
} from "@material-ui/core";
import ConfimationAction from "../../ConfirmationAction/ConfirmationAction";
import MailIcon from "@material-ui/icons/Mail";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditUser from "../../../Forms/EditUser/EditUser";
import TransitionsModal from "../../Modal/TransitionsModal";
import { LoginContext } from "../../../../context/LoginContext";
import LockOpenIcon from "@material-ui/icons/LockOpen";
const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 400,
        marginBottom: theme.spacing(3),
    },
    edit: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        padding: 5,
    },
}));

const UserActionsCard = (props) => {
    let classes = useStyles();
    let {
        makeAdmin,
        deleteUser,
        restoreUser,
        user,
        parentUpdate,
        resetPassword,
    } = props;
    const [modal, setmodal] = useState(false);
    const [loggedInUser] = useContext(LoginContext);
    const openModal = () => {
        setmodal(!modal);
    };
    const emailUser = () => {
        window.open(`mailto:${user.email}`);
    };

    return (
        <Card className={classes.card} raised>
            <CardHeader
                title="User Actions"
                style={{ textAlign: "center" }}
            ></CardHeader>
            <CardContent>
                <Grid container>
                    <Grid item xs={6}>
                        <CardActions>
                            <TransitionsModal
                                content={
                                    <EditUser
                                        parentUpdate={parentUpdate}
                                        user={user}
                                    />
                                }
                                buttonColor="secondary"
                                buttonContent={<EditIcon />}
                                buttonType="icon"
                                parentUpdate={parentUpdate}
                                open={modal}
                                openModal={openModal}
                            />{" "}
                            Edit
                        </CardActions>
                        <CardActions>
                            <ConfimationAction
                                message="Email"
                                icon={<MailIcon size="small" />}
                                question={`Write email to ${user.name}?`}
                                parentAction={() => emailUser()}
                            />
                        </CardActions>
                        <CardActions>
                            <ConfimationAction
                                message="Password"
                                icon={<LockOpenIcon size="small" />}
                                parentAction={() => resetPassword()}
                                question="Send reset password email?"
                            />
                        </CardActions>
                    </Grid>
                    <Grid item xs={6}>
                        <CardActions>
                            {user.is_active ? (
                                <ConfimationAction
                                    message="Deactivate"
                                    icon={<DeleteIcon size="small" />}
                                    parentAction={() => deleteUser()}
                                    question={"Delete User?"}
                                />
                            ) : (
                                <ConfimationAction
                                    message="Restore"
                                    icon={
                                        <SettingsBackupRestoreIcon size="small" />
                                    }
                                    parentAction={() => restoreUser()}
                                    question={"Restore User?"}
                                />
                            )}
                        </CardActions>
                        {loggedInUser && loggedInUser.is_admin == 1 && (
                            <CardActions>
                                {user.is_admin == 0 ? (
                                    <ConfimationAction
                                        message="Not Admin"
                                        icon={<HighlightOffIcon size="small" />}
                                        parentAction={() => makeAdmin()}
                                        question={"Add Admin Privliges?"}
                                    />
                                ) : (
                                    <ConfimationAction
                                        message="Admin"
                                        icon={
                                            <SupervisorAccountIcon size="small" />
                                        }
                                        parentAction={() => makeAdmin()}
                                        question={"Remove Admin Privliges?"}
                                    />
                                )}
                            </CardActions>
                        )}
                    </Grid>
                    {/* <Grid item xs={6}>
                        <CardActions>
                            <ConfimationAction
                                message="Password"
                                icon={<LockOpenIcon size="small" />}
                                parentAction={() => resetPassword()}
                                question="Are you sure you want to reset Password?"
                            />
                        </CardActions>
                    </Grid> */}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default UserActionsCard;
