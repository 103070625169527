import React, { useState, useEffect, useContext } from "react";

import axios from "axios";

import { useHistory } from "react-router-dom";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, AppBar, Tabs, Card, Tab, Box } from "@material-ui/core";
import DeviceCard from "../../Features/Cards/Device/DeviceCard";
import LoadingPage from "../../Pages/LoadingPage/LoadingPage";
import Notes from "../../Features/Cards/NotesCard/NotesCard";
import ClientDetailCard from "../../Features/Cards/Client/ClientDetailCard";
import LogsTable from "../../Features/Table/LogsTable";
import ClientActionsCard from "../../Features/Cards/Client/ClientActionsCard";
import { LoginContext } from "../../../context/LoginContext";
import DeviceAddCard from "../../Features/Cards/Device/DeviceAddCard";
import ClientNotActivatedPage from "./ClientNotActivatedPage";
import { withSnackbar } from "../../HOC/SnackbarHOC";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
    },
    deviceContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        margin: ".5rem",
    },

    notActive: {
        height: "80vh",
        width: "100%",
    },
}));

const ClientPage = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [client, setclient] = useState([]);
    const [active, setactive] = useState(false);
    const [logs, setlogs] = useState([]);
    const [modal, setmodal] = useState(false);
    const [loading, setloading] = useState(true);
    const [loggedInUser, setloggedInUser] = useContext(LoginContext);
    const [logsPaginate, setlogsPaginate] = useState();
    const [tabvalue, settabvalue] = useState(0);
    const [pageLoad, setpageLoad] = useState(true);
    useEffect(() => {
        getClient();
        getClientLogs();
    }, []);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={0}>{children}</Box>}
            </div>
        );
    }

    const getClient = () => {
        if (pageLoad) {
            setloading(true);
            setpageLoad(false);
        }
        axios
            .get(`api/protected/clients/${props.match.params.id}`)
            .then((results) => {
                setclient(results.data.client);
                setactive(results.data.client.deleted_at ? false : true);
                setloading(false);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    //place your reentry code
                    history.push("/");
                }
                if (error.response.status === 404) {
                    // history.push("/");
                    console.log(error);
                }
            });
    };

    const getClientLogs = () => {
        axios
            .get(`api/protected/clients/${props.match.params.id}/logs`)
            .then((results) => {
                setlogs(results.data.data);
                setlogsPaginate(results.data);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    //place your reentry code
                    history.push("/");
                }
                if (error.response.status === 404) {
                    // history.push("/");
                    console.log(error);
                }
            });
    };

    const getPage = (e, page) => {
        // setloading(true);
        let pagePlus = page + 1;
        axios
            .get(logsPaginate.path, {
                params: {
                    page: pagePlus,
                },
            })
            .then((results) => {
                setlogs(results.data.data);
                setlogsPaginate(results.data);
            })
            .catch((error) => {
                setloading(false);
                console.log(error);
            });
    };
    const deleteClient = (e) => {
        axios
            .delete(`api/protected/clients/${client.id}`)
            .then((response) => {
                // calling the getClients method in the parent component
                getClient();
                getClientLogs();
                props.snackbarShowMessage(`Client has been deactived`);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const restoreClient = () => {
        axios
            .post(`api/protected/clients/${client.id}/restore`)
            .then((response) => {
                getClient();
                getClientLogs();
                props.snackbarShowMessage(`Client has been restored`);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const createNote = (note) => {
        let id = loggedInUser.id;

        axios
            .post(`api/protected/clients/${client.id}/notes`, {
                note: note,
                user_id: id,
            })
            .then((response) => {
                getClient();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const deletClientNote = (note) => {
        axios
            .delete(`api/protected/clients/${client.id}/notes/${note.id}`)
            .then((response) => {
                // call the device to update the changes
                getClient();
                props.snackbarShowMessage(`Note Has Been Deleted`);
                // give delete note success response
            })
            .catch((error) => {
                // give delete note error response
            });
    };

    const openModal = () => {
        setmodal(!modal);
    };

    const tabChange = (event, newValue) => {
        settabvalue(newValue);
    };

    return (
        <>
            {loading ? (
                <LoadingPage />
            ) : (
                <>
                    {!client.is_active ? (
                        <ClientNotActivatedPage
                            client={client}
                            restoreClient={restoreClient}
                        ></ClientNotActivatedPage>
                    ) : (
                        <Grid
                            container
                            alignItems="flex-start"
                            justify="space-between"
                            spacing={2}
                        >
                            <Grid item xs={12} sm={6} md={8}>
                                <ClientDetailCard
                                    client={client}
                                ></ClientDetailCard>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ClientActionsCard
                                    client={client}
                                    active={active}
                                    deletClient={deleteClient}
                                    restoreClient={restoreClient}
                                    parentUpdate={() => {
                                        getClient();
                                        getClientLogs();
                                    }}
                                />
                            </Grid>
                            <Typography variant="h6">
                                Devices({client.device_count})
                            </Typography>
                            <Grid
                                container
                                spacing={3}
                                className={classes.deviceContainer}
                            >
                                <Grid item xs={6} sm={4} md={2}>
                                    <DeviceAddCard
                                        client={client}
                                        parentUpdate={getClient}
                                    />
                                </Grid>
                                {client.devices.map((device, key) => {
                                    return (
                                        <Grid
                                            item
                                            xs={6}
                                            sm={4}
                                            md={2}
                                            key={`device_${key}_${device.id}`}
                                        >
                                            <DeviceCard
                                                device={device}
                                                client={client}
                                                updateParent={getClient}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Card raised>
                                    <AppBar position="static">
                                        <Tabs
                                            value={tabvalue}
                                            onChange={tabChange}
                                            aria-label="simple tabs example"
                                        >
                                            <Tab
                                                label="Notes"
                                                id="simple-tab-notes"
                                                aria-controls="simple-tabpanel-notes"
                                            />
                                            <Tab
                                                label="Logs"
                                                id="simple-tab-logs"
                                                aria-controls="simple-tabpanel-logs"
                                            />
                                        </Tabs>
                                    </AppBar>

                                    <div
                                        role="tabpanel"
                                        hidden={tabvalue !== 0}
                                        id="simple-tabpanel-notes"
                                        aria-labelledby="simple-tab-notes"
                                    >
                                        {tabvalue === 0 && (
                                            <Notes
                                                notes={client.notes}
                                                updateNotes={(item) =>
                                                    createNote(item)
                                                }
                                                deleteNote={(note) =>
                                                    deletClientNote(note)
                                                }
                                                title="Device Notes"
                                            ></Notes>
                                        )}
                                    </div>
                                    <div
                                        role="tabpanel"
                                        hidden={tabvalue !== 1}
                                        id="simple-tabpanel-logs"
                                        aria-labelledby="simple-tab-logs"
                                    >
                                        {tabvalue === 1 && (
                                            <LogsTable
                                                logs={logs}
                                                total={`Total Logs: ${logsPaginate.total}`}
                                                logsPaginate={logsPaginate}
                                                getPage={getPage}
                                            ></LogsTable>
                                        )}
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};

export default withSnackbar(ClientPage);
