import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import LogRow from "./LogRow";
import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    user: {
        backgroundColor: theme.palette.background.paper,
    },
    noLink: {
        textDecoration: "none",
        color: "inherit",
    },
    table: {
        minWidth: 650,
        maxWidth: "100%",
    },
}));
const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
});

const LogsTable = (props) => {
    let { logs, getPage, logsPaginate } = props;
    let classes = useStyles();

    return (
        <Paper>
            <TableContainer component={Paper}>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Id</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="left">Subject Type</TableCell>
                            <TableCell align="left">Subject</TableCell>
                            <TableCell align="left">Causer</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs.map((log, index) => (
                            <LogRow log={log} key={`${index}-${log.id}`} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={logsPaginate.total}
                rowsPerPage={logsPaginate.per_page}
                // has to start at 0 index
                page={logsPaginate.current_page - 1}
                onChangePage={getPage}
            />
        </Paper>
    );
};

export default LogsTable;
