import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Grid, Typography, AppBar, Tabs, Tab } from "@material-ui/core";
import axios from "axios";
import KpiCard from "../../Features/Cards/Dashboard/KpiCard";
import OsCard from "../../Features/Cards/Dashboard/OsCard";
import TypeCard from "../../Features/Cards/Dashboard/TypeCard";
import { LoginContext } from "../../../context/LoginContext";
import LogsTable from "../../Features/Table/LogsTable";
import DeviceCard from "../../Features/Cards/Device/DeviceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    handshakeDiv: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1),
        color: theme.palette.secondary.mian,
    },
}));

const HomePage = (props) => {
    const history = useHistory();

    const [dashboard, setdashboard] = useState({});
    const classes = useStyles();
    const [kpis, setkpis] = useState([]);
    const [devicesOS, setdevicesOS] = useState([]);
    const [deviceType, setdeviceType] = useState([]);
    const [loggedInUser, setloggedInUser] = useContext(LoginContext);
    const [logs, setlogs] = useState([]);
    const [logsPaginate, setlogsPaginate] = useState({});
    const [tabvalue, settabvalue] = useState(0);
    const [activeDevices, setactiveDevices] = useState([]);

    const getDashboard = () => {
        axios
            .get("/api/protected/dashboard")
            .then((res) => {
                setdashboard(res.data);
                setkpis(res.data.kpis);
                setdevicesOS(res.data.devices_os);
                setdeviceType(res.data.devices_type);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    console.log("auth error");
                    // history.push("/");
                }
            });
    };

    const kpiCards = kpis.map((kpi, index) => {
        return (
            <Grid item xs={12} sm={6} md={4} key={index + "_kpi"}>
                <KpiCard kpi={kpi} />
            </Grid>
        );
    });

    const typeCards = deviceType.map((kpi, index) => {
        return (
            <Grid item xs={6} sm={6} md={3} key={index + "_type"}>
                <TypeCard kpi={kpi} />
            </Grid>
        );
    });

    const getDashboardLogs = () => {
        axios
            .get("/api/protected/dashboard/logs")
            .then((res) => {
                setlogs(res.data.data);
                setlogsPaginate(res.data);
            })
            .catch((error) => {});
    };

    const getActiveDevices = () => {
        axios
            .get("/api/protected/dashboard/active/devices")
            .then((res) => {
                setactiveDevices(res.data);
            })
            .catch((error) => {});
    };

    const getPage = (e, page) => {
        // setloading(true);
        let pagePlus = page + 1;
        axios
            .get(logsPaginate.path, {
                params: {
                    page: pagePlus,
                },
            })
            .then((results) => {
                setlogs(results.data.data);
                setlogsPaginate(results.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const tabChange = (event, newValue) => {
        settabvalue(newValue);
    };

    let deviceCards = activeDevices ? (
        activeDevices.map((device, i) => {
            return (
                <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={2}
                    key={`${device.device.name}-${device.device.id}`}
                >
                    <DeviceCard
                        device={device.device}
                        client={device.device.client}
                    />

                    <div className={classes.handshakeDiv}>
                        <FontAwesomeIcon icon={faHandshake}></FontAwesomeIcon>
                        {device.latest_handshake}
                    </div>
                </Grid>
            );
        })
    ) : (
        <Typography variant="h4"> No Results</Typography>
    );

    useEffect(() => {
        getDashboard();
        getDashboardLogs();
        getActiveDevices();
    }, []);

    return (
        <div className={classes.root}>
            <Grid container direction={"column"} spacing={2}>
                {/* <Typography variant="h3">Hey {loggedInUser.name}!</Typography> */}

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {kpiCards}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {typeCards}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <AppBar position="static">
                        <Tabs
                            value={tabvalue}
                            onChange={tabChange}
                            aria-label="simple tabs example"
                        >
                            <Tab
                                label="Active Devices"
                                id="simple-tab-devices"
                                aria-controls="simple-tabpanel-devices"
                            />
                            <Tab
                                label="Logs"
                                id="simple-tab-logs"
                                aria-controls="simple-tabpanel-logs"
                            />
                        </Tabs>
                    </AppBar>

                    <div
                        role="tabpanel"
                        hidden={tabvalue !== 0}
                        id="simple-tabpanel-devices"
                        aria-labelledby="simple-tab-devices"
                    >
                        {tabvalue === 0 && (
                            <Grid container spacing={3}>
                                {deviceCards}
                            </Grid>
                        )}
                    </div>
                    <div
                        role="tabpanel"
                        hidden={tabvalue !== 1}
                        id="simple-tabpanel-logs"
                        aria-labelledby="simple-tab-logs"
                    >
                        {tabvalue === 1 && (
                            <LogsTable
                                logs={logs}
                                total={`Total Logs: ${logsPaginate.total}`}
                                logsPaginate={logsPaginate}
                                getPage={getPage}
                            ></LogsTable>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default HomePage;
