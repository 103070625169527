import React, { useState, useEffect, createContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

export const LoginContext = createContext();

export const LoginProvider = (props) => {
    const history = useHistory();
    const [loggedInUser, setloggedInUser] = useState({});
    const [auth, setauth] = useState(false);

    const getUserLoggedIn = (cb) => {
        axios
            .get("api/protected/user")
            .then((response) => {
                setloggedInUser(response.data);
                sessionStorage.setItem("user", response.data);
                cb();
            })
            .catch((error) => {
                setloggedInUser({});
                sessionStorage.removeItem("user");
                history.push("/");
            });
    };

    const checkAuth = async () => {
        axios
            .get("api/protected/user")
            .then((response) => {
                console.log(response);
                setloggedInUser(response.data);
                setauth(true);
                return true;
            })
            .catch((error) => {
                setloggedInUser({});
                sessionStorage.removeItem("user");
                setauth(false);
                return false;
            });
        return auth;
    };

    const logoutUser = () => {
        axios
            .post("/logout")
            .then((res) => {
                setloggedInUser({});
                sessionStorage.removeItem("user");
                history.push("/");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loginUser = (email, password) => {
        axios.get("/sanctum/csrf-cookie").then((response) => {
            axios
                .post("/login", {
                    email,
                    password,
                })
                .then((response) => {
                    getUserLoggedIn();
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    return (
        <LoginContext.Provider
            value={[
                loggedInUser,
                setloggedInUser,
                getUserLoggedIn,
                logoutUser,
                loginUser,
                auth,
                checkAuth,
            ]}
        >
            {props.children}
        </LoginContext.Provider>
    );
};
