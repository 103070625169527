import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    card: {
        marginBottom: theme.spacing(3),
    },
    deviceInfoCardContent: {
        // height: 100,
        // display: 'flex',
        // justifyContent: 'center',
    },
    table: {
        maxWidth: 400,
    },
    tableHeader: {
        backgroundColor: "#848080",
        width: 120,
    },

    rightContainer: {},

    header: {
        textAlign: "center",
    },

    trash: {
        "&:hover": {
            color: theme.palette.error.dark,
            backgroundColor: theme.palette.error.light,
        },
        padding: 5,
    },

    email: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        padding: 5,
    },
    edit: {
        "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        padding: 5,
    },

    deviceContainer: {
        position: "relative",
    },

    margin: {
        margin: theme.spacing(1),
    },

    slideContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
}));
const DeviceFullDetailsCard = (props) => {
    const classes = useStyles();
    let { moreDetails, device } = props;

    useEffect(() => {}, [moreDetails]);

    const getUnixFormat = (unixTime) => {
        if (unixTime == 0) {
            return "Not Conencted";
        } else {
            return moment.unix(unixTime).format("MM/DD/YYYY h:m:s");
        }
    };
    return (
        <Card className={classes.card} raised>
            {/* <CardHeader
                title="Details"
                style={{ textAlign: "center" }}
                className={classes.header}
            /> */}
            <CardContent className={classes.deviceInfoCardContent}>
                <TableContainer component={Paper}>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead></TableHead>
                        <TableBody>
                            {/* <TableRow>
                                <TableCell
                                    component="th"
                                    className={classes.tableHeader}
                                >
                                    Name
                                </TableCell>
                                <TableCell component="th">
                                    {device.name}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    className={classes.tableHeader}
                                >
                                    Type
                                </TableCell>
                                <TableCell component="th">
                                    {device.type}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    className={classes.tableHeader}
                                >
                                    OS
                                </TableCell>
                                <TableCell component="th">
                                    {device.os}
                                </TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell
                                    component="th"
                                    className={classes.tableHeader}
                                >
                                    Created At
                                </TableCell>
                                <TableCell component="th">
                                    {moment(device.created_at).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    className={classes.tableHeader}
                                >
                                    Updated At
                                </TableCell>
                                <TableCell component="th">
                                    {moment(device.updated_at).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    className={classes.tableHeader}
                                >
                                    Status
                                </TableCell>
                                <TableCell component="th">
                                    {device.deleted_at
                                        ? ` deactivated  ${moment(
                                              device.deleted_at
                                          ).format("MMMM Do YYYY, h:mm:ss a")} `
                                        : "Active"}
                                </TableCell>
                            </TableRow>
                            {moreDetails && (
                                <>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            className={classes.tableHeader}
                                        >
                                            Allowed Ips
                                        </TableCell>
                                        <TableCell component="th">
                                            {moreDetails["allowed-ips"]}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            className={classes.tableHeader}
                                        >
                                            Endpoint
                                        </TableCell>
                                        <TableCell component="th">
                                            {moreDetails.endpoint}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            className={classes.tableHeader}
                                        >
                                            Latest Handshake
                                        </TableCell>
                                        <TableCell component="th">
                                            {getUnixFormat(
                                                moreDetails["latest-handshake"]
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            className={classes.tableHeader}
                                        >
                                            Keepalive
                                        </TableCell>
                                        <TableCell component="th">
                                            {
                                                moreDetails[
                                                    "persistent-keepalive"
                                                ]
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            className={classes.tableHeader}
                                        >
                                            Transfer-rx
                                        </TableCell>
                                        <TableCell component="th">
                                            {moreDetails["transfer-rx"]}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            className={classes.tableHeader}
                                        >
                                            Transfer-Tx
                                        </TableCell>
                                        <TableCell component="th">
                                            {moreDetails["transfer-tx"]}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default DeviceFullDetailsCard;
